import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {StepsDataService} from '../../../services/steps-data.service';
import {PersonalData} from '../../../models/personal-data';
import {Registration} from '../../../models/registration';
import {UserDataService} from '../../../services/user-data.service';
import {RegistrationService} from '../../../models/services/registration.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  personalData: PersonalData;
  currentRegistration: Registration;

  constructor(private router: Router,
              private _userDataService: UserDataService,
              private _registrationService: RegistrationService,
              private _stepsDataService: StepsDataService) {}

  ngOnInit() {
    this.personalData = this._userDataService.personalData;
    this.currentRegistration = this._userDataService.registration;
  }

  downloadCOVID19DeclarationDocument(): void {
    const link = document.createElement('a');
    link.href = `svc/admissions/person/${this.personalData.id}/admissionRegistrations/${this.currentRegistration.id}/covid/download`;
    link.target = '_blank';
    // tslint:disable-next-line
    link.download;
    link.click();
  }

  finish() {
    this.router.navigate(['dashboard/profile']);
  }

  getRegistration(registrationId) {
    this._registrationService.getRegistration$(registrationId).subscribe( (res: any) => {
      if (res.status.code === 'STATUS_OK') {
        this.currentRegistration = res.body.data;
      }
    });
  }

}
