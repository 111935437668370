
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UserDataService} from '../../../services/user-data.service';
import {Router} from '@angular/router';
import {StepsDataService} from '../../../services/steps-data.service';
import {User} from '../../../models/user';
import {PersonalData} from '../../../models/personal-data';
import {Registration} from '../../../models/registration';
import {PersonalDataService} from '../../../models/services/personal-data.service';
import {Steps} from '../../enum-steps';
import {BrowserUser} from '../../../models/browser-user';

@Component({
  selector: 'app-registration-type',
  templateUrl: './registration-type.component.html',
  styleUrls: ['./registration-type.component.css']
})
export class RegistrationTypeComponent implements OnInit {
  avatar;

  @ViewChild('avatar')
  set avatarChild(avatar: Component) {
    if (avatar) {
      setTimeout(() => {
        this.avatar = avatar;
        this.avatar.src = 'anag/' + BrowserUser.getAnagraficaId() + '.jpg';
      });
    }
  }

  constructor(
    private _userData: UserDataService,
    private _personalData: PersonalDataService,
    private _stepService: StepsDataService,
    private _routes: Router
  ) {}

  currentUser: User;
  registration: Registration = new Registration();
  personalData: PersonalData = new PersonalData();

  type1 = false;
  type2 = false;
  type3 = false;
  registrationType = '';

  REGISTER = 'ADM_REG_TYPE_00';
  TRANSFER = 'ADM_REG_TYPE_01';
  SECONDPROGRAM = 'ADM_REG_TYPE_02';


  ngOnInit() {
    this._userData
      .user$
      .subscribe((user: User) => {
        this.currentUser = user;
        if (this.currentUser) {

          setTimeout(() => this._stepService.markStep(Steps.stepThree));

          this._personalData
            .getUserData(this.currentUser.userAttributes.ADMISSIONS__APP.attributeValue)
            .subscribe((result: any) => {
              this.personalData = result.body.data;
              this._userData.personalData = this.personalData;
            });
        }
      });
  }

  disableAll() {
    this.type1 = false;
    this.type2 = false;
    this.type3 = false;
  }

  isSelected() {
    return this.type1 === true || this.type2 === true || this.type3 === true;
  }

  registerType(_type) {
    if (_type === this.REGISTER) {

      this.disableAll();
      this.type1 = true;
      this.registrationType = this.REGISTER;

    } else if (_type === this.TRANSFER) {

      this.disableAll();
      this.type2 = true;
      this.registrationType = this.TRANSFER;

    } else if (_type === this.SECONDPROGRAM) {

      this.disableAll();
      this.type3 = true;
      this.registrationType = this.SECONDPROGRAM;

    }
  }

  goToPreferences() {
    this.registration.status = 'editing';
    // this.registration.registrationType = this.registrationType;
    this._userData.registration = this.registration;

    if (this.personalData.countryOfHighSchool === 'ALB') {
      this._routes.navigate(['dashboard/admission-courses', this.registrationType]);
    } else {
      this._routes.navigate(['dashboard/admission-courses', this.registrationType]);
    }
  }

  goBack() {
    this._routes.navigate(['dashboard/profile']);
  }
}
