import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-portal-privacy',
  templateUrl: './portal-privacy.component.html',
  styleUrls: ['./portal-privacy.component.css']
})
export class PortalPrivacyComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<PortalPrivacyComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
