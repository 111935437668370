import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {DialogDeleteComponent} from '../../../dialogs/dialog-delete/dialog-delete.component';
import {DialogWarningComponent} from '../../../dialogs/dialog-warning/dialog-warning.component';
import {SummaryDialogComponent} from '../../../dialogs/summary-dialog/summary-dialog.component';
import {UserDataService} from '../../../services/user-data.service';
import {StepsDataService} from '../../../services/steps-data.service';
import {PersonalDataService} from '../../../models/services/personal-data.service';
import {NotificationService} from '../../../models/services/notification.service';
import {RegistrationService} from '../../../models/services/registration.service';
import {TranslateService} from '@ngx-translate/core';
import {PersonalData} from '../../../models/personal-data';
import {Registration} from '../../../models/registration';
import {BrowserUser} from '../../../models/browser-user';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css']
})
export class ProfilePageComponent implements OnInit {
  personalData: PersonalData;
  registrations: Registration[];
  dictionary: any;
  noRegistration = false;

  constructor(
    public dialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _snack: MatSnackBar,
    private _stepsDataService: StepsDataService,
    private _userService: UserDataService,
    private _notifications: NotificationService,
    private _personalData: PersonalDataService,
    private _registration: RegistrationService,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getRegistrations();
    this.personalData = this._route.snapshot.data.parentPersonData;

    this._translateService
      .onLangChange
      .subscribe(translate => this.dictionary = translate.translations);

    this._translateService
      .getTranslation(BrowserUser.getLang())
      .subscribe(dictionary => this.dictionary = dictionary);
  }

  getRegistrations() {
    this._registration
      .getRegistrations()
      .subscribe((registrations: any) => {
        if (registrations.status.code === 'STATUS_OK') {
          this.registrations = registrations.body.data.list;
          this.noRegistration = true;

          if (this.registrations.length === 1) {
            this._stepsDataService.registration = {...this.registrations[0]};
          }
        }
      });
  }

  newSubmission() {
    this._router
      .navigate(
        !this.personalData.personComplete ?
          ['dashboard/anagrafica'] :
          ['dashboard/registration-type']
      ).then();
  }

  goToStep(registration: Registration) {
    const {
      id,
      allRequiredDocumentsUploaded,
      documentsCheckCompleted,
      prepaymentCompleted,
      prepaymentInvoiceId,
      avgGradeCheckCompleted,
      paymentCompleted,
      paymentInvoiceId
    } = registration;

    if (!allRequiredDocumentsUploaded) {
      this._router.navigate(['dashboard', 'admission-documents', id]);
    } else if (!prepaymentCompleted) {
      this._router.navigate(['dashboard', 'prepayment', prepaymentInvoiceId, id]);
    } else if (!documentsCheckCompleted || !avgGradeCheckCompleted) {
      this._router.navigate(['dashboard', 'confirmation', id]);
    } else if (!paymentCompleted) {
      this._router.navigate(['dashboard', 'payment', paymentInvoiceId, id]);
    } else {
      this._router.navigate(['dashboard', 'result', id]);
    }
  }

  checkDocuments(registration) {
    this._stepsDataService.registration = registration;
    this._router.navigate(['dashboard/admission-documents', registration.id]);
  }

  goToResults() {
    this._router.navigate(['dashboard/result']);
  }

  viewSummary(registration: Registration) {
    this._userService.registration = registration;

    this.dialog.open(SummaryDialogComponent, {
      width: '1000px',
      data: {
        title: this.dictionary.profile_step.summary,
        registrationId: registration.id
      }
    });
  }

  cancelSubscription(registration: Registration) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '600px',
      data: {
        title: this.dictionary.global.are_you_sure_to_cancel_your_submition,
        message: registration.labelMap.registrationType,
        description: registration.examName
      }
    });

    dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._registration
            .cancelSubmition(registration.personId, registration.id)
            .subscribe((res: any) => {
              if (res.status.code === 'STATUS_OK') {
                this._snack.open(
                  this.dictionary.profile_step.submission_canceled,
                  null, {
                    duration: 5000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                  }
                );
                this.noRegistration = false;
                this.getRegistrations();
              } else {
                this._snack.open(
                  res.status.message,
                  null, {
                    duration: 5000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                  }
                );
              }
            });
        }
      });
  }

}
