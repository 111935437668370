import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {UserDataService} from '../../services/user-data.service';
import {Subject} from 'rxjs';
import {BrowserUser} from '../browser-user';
import {filter, tap} from 'rxjs/operators';
import {isStatusOk} from '../../functions/response-status/is-status-ok';
import {ResponseModel} from '../response.model';
import {PersonalData} from '../personal-data';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class PersonalDataService {

  _updateProfile$ = new Subject<string>();

  constructor(private _httpClient: HttpClient, private _userService: UserDataService) { }

  getUserData(id: string = BrowserUser.getAnagraficaId()): Observable<ResponseModel<PersonalData>> {
    return this._httpClient
      .get<ResponseModel<PersonalData>>(`${environment.devUrl}/person/${id}`)
      .pipe(
        filter(res => isStatusOk(res)),
        tap(res => this._userService.personalData = res.body.data)
      );
  }

  updatePersonalPhoto(personId: string, attachment) {
    return this._httpClient.post(`${environment.devUrl}/person/${personId}/photo`, attachment);
  }

  savePersonalData(personalData) {
    return this._httpClient.put(`${environment.devUrl}/${'person'}/${personalData.id}`, personalData);
  }

  getCountries() {
    const params: HttpParams = new HttpParams().set('paramBean', JSON.stringify({pageNo: 1, pageSize: -1}));

    return this._httpClient.get(`${environment.devUrl}/pool/country`, {params});
  }

}
