import {AbstractControl, FormArray, FormControl} from '@angular/forms';

export class FormUtility {
  static markAllDirty(control: AbstractControl) {
    if (control.hasOwnProperty('controls')) {
      control.markAsDirty({onlySelf: true}); // mark group
      const ctrl = <any>control;

      for (const inner in ctrl.controls) {
        if (ctrl.controls.hasOwnProperty(inner)) {
          this.markAllDirty(ctrl.controls[inner] as AbstractControl);
        }
      }
    } else {
      (<FormControl>(control)).markAsDirty({onlySelf: true});
      (<FormControl>(control)).markAsTouched({onlySelf: true});
    }
  }

  static markAllPristine(control: AbstractControl) {
    if (control.hasOwnProperty('controls')) {
      control.markAsPristine({onlySelf: true}); // mark group
      const ctrl = <any>control;

      for (const inner in ctrl.controls) {
        if (ctrl.controls.hasOwnProperty(inner)) {
          this.markAllPristine(ctrl.controls[inner] as AbstractControl);
        }
      }
    } else {
      (<FormControl>(control)).markAsPristine({onlySelf: true});
    }
  }

  static updateAllValueAndFormValidity(control: AbstractControl) {
    if (control.hasOwnProperty('controls')) {
      control.updateValueAndValidity({onlySelf: true}); // mark group
      const ctrl = <any>control;

      for (const inner in ctrl.controls) {
        if (ctrl.controls.hasOwnProperty(inner)) {
          this.updateAllValueAndFormValidity(ctrl.controls[inner] as AbstractControl);
        }
      }
    } else {
      (<FormControl>(control)).updateValueAndValidity();
    }
  }

  static clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }
}
