import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {QueueService} from '../../../services/queue.service';

@Component({
  selector: 'app-queue-panel',
  templateUrl: './queue-panel.component.html',
  styleUrls: ['./queue-panel.component.css']
})
export class QueuePanelComponent implements OnInit, OnDestroy {

  constructor(
    private queueService: QueueService
  ) { }

  queueNumber = 0;
  timer = Observable.interval(5000).subscribe(() => {
    this.getActiveNumber();
  });

  ngOnInit() {
    this.getActiveNumber();
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
  }

  getActiveNumber() {
    this.queueService.getQueueBumber().subscribe( (result: any) => {
      if (result.status.code === 'STATUS_OK') {
        this.queueNumber = result.body.data.activeNumber;
      }
    });
  }

}
