export class User {
  userApps: any;
  username: string;
  fullName: string;
  lang: string;
  blocked: boolean;
  lastLogin: string;
  passwordResetUuid: string;
  userAccessLevel: number;
  countDownSeconds: number;
  serviceUuid: string;
  authToken: string;
  userAttributes: UserAttributes;
  appPermissions: any;
  labelMap: any;
  id: string;
  someLabel: string;
  email: string;
  anagId: string;

  constructor(initData?) {
    if (!initData) {
      return;
    }

    this.userApps = initData.userApps;
    this.username = initData.username;
    this.fullName = initData.fullName;
    this.lang = initData.lang;
    this.blocked = initData.blocked;
    this.lastLogin = initData.lastLogin;
    this.passwordResetUuid = initData.passwordResetUuid;
    this.userAccessLevel = initData.userAccessLevel;
    this.countDownSeconds = initData.countDownSeconds;
    this.serviceUuid = initData.serviceUuid;
    this.authToken = initData.authToken;
    this.userAttributes = initData.userAttributes;
    this.appPermissions = initData.appPermissions;
    this.labelMap = initData.labelMap;
    this.id = initData.id;
    this.someLabel = initData.someLabel;
    this.email = initData.email;
    if (initData.userAttributes) {
      this.anagId = initData.userAttributes.ADMISSIONS__APP.attributeValue;
    }
  }
}


export interface UserAttributes {
  ADMISSIONS__APP: ADMISSIONSAPP2;
  // HR_MODULES__APP: HRMODULESAPP2;
  // APPS00000000003: APPS000000000032;
  // ODONTO___WEBAPP: ODONTOWEBAPP2;
  // APPUSER00000011: APPUSER00000011;
}

export interface ADMISSIONSAPP2 {
  appUserId: string;
  attributeKey: string;
  attributeValue: string;
  labelMap: any;
  id: string;
  someLabel: string;
}
