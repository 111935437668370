import {Component, Input, OnInit} from '@angular/core';
import {Invoice} from '../models/invoice';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {RegistrationService} from '../models/services/registration.service';
import {TranslateService} from '@ngx-translate/core';
import {StepsDataService} from '../services/steps-data.service';
import {PersonalData} from '../models/personal-data';
import {isStatusOk} from '../functions/response-status/is-status-ok';
import {Steps} from '../steps/enum-steps';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  private _registrationId?: string;
  private _invoiceId?: string;

  invoice: Invoice;

  @Input()
  title?: string;

  @Input()
  disablePaymentButton: boolean;

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _matSnackBar: MatSnackBar,
              private _registrationService: RegistrationService,
              private _translateService: TranslateService,
              private _stepsDataService: StepsDataService) {
    this.disablePaymentButton = false;
  }

  ngOnInit() {
    this._registrationId = this._activatedRoute.snapshot.paramMap.get('id');
    this._invoiceId = this._activatedRoute.snapshot.paramMap.get('invoiceId');

    this._registrationService
      .getPaymentInvoice(this._registrationId, this._invoiceId)
      .subscribe(res => this.invoice = res);
  }

  get fullName(): string {
    const {firstName, lastName} = this._activatedRoute.snapshot.data.parentPersonData as PersonalData;
    return `${firstName} ${lastName}`;
  }

  get examName(): string {
    return this._stepsDataService.registration.examName;
  }

  get isPaid(): boolean {
    return this.invoice.paid;
  }

  continue() {
    this._router
      .navigateByUrl('dashboard/profile')
      .then(() => this._stepsDataService.markStep(Steps.stepOne));
  }

  payInvoice(): void {
    this._registrationService
      .payPaymentInvoice(this._registrationId, this._invoiceId)
      .subscribe(res => {
        if (isStatusOk(res)) {
          window.open(res.body.data, '_self');
        } else {
          this._matSnackBar
            .open(
              res.status.message || res.status.code,
              this._translateService.instant('global.dismiss'),
              {
                duration: 15000,
                horizontalPosition: 'left'
              }
            );
        }
      });
  }
}
