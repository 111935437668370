import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StepsDataService} from '../../../services/steps-data.service';

@Component({
  selector: 'app-prepayment',
  templateUrl: './prepayment.component.html',
  styleUrls: ['./prepayment.component.css']
})
export class PrepaymentComponent {
  constructor(private _stepsDataService: StepsDataService,
              private _translateService: TranslateService) {}

  get title(): string {
    return this._translateService.instant('prepayment_step.prepayment');
  }

  get disablePrepaymentButton(): boolean {
    if (this._stepsDataService.registration) {
      const {prepaymentCompleted} = this._stepsDataService.registration;
      return prepaymentCompleted;
    }

    return false;
  }
}
