import { Component, OnInit } from '@angular/core';
import {QueueService} from '../../../services/queue.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormUtility} from '../../../utitlities/FormUtilities';
import {Router} from '@angular/router';

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.css']
})
export class QueueComponent implements OnInit {

  constructor( private queueService: QueueService,
               private router: Router,
               private formBuilder: FormBuilder) { }

  queueNumber: number;
  form: FormGroup;

  ngOnInit() {
    this.getQueueNumber();
    this.form = this.formBuilder.group({
      number: ['', Validators.required]
    });
  }

  getQueueNumber() {
    this.queueService.getQueueBumber().subscribe( (result: any) => {
      if (result.status.code === 'STATUS_OK') {
        this.queueNumber = result.body.data.activeNumber;
      }
    });
  }

  resetNumber() {
    this.queueService.resetNumber().subscribe((result: any) => {
      if (result.status.code === 'STATUS_OK') {
        this.queueNumber = result.body.data.activeNumber;
      }
    });
  }

  incrementNumber() {
    const temp = this.queueNumber + 1;
    this.queueService.updateNumber(temp).subscribe((result: any) => {
      if (result.status.code === 'STATUS_OK') {
        this.queueNumber = result.body.data.activeNumber;
      }
    });
  }

  decrementNumber() {
    const temp = this.queueNumber - 1;
    this.queueService.updateNumber(temp).subscribe((result: any) => {
      if (result.status.code === 'STATUS_OK') {
        this.queueNumber = result.body.data.activeNumber;
      }
    });
  }

  setNumber() {
    if (this.form.valid) {
      this.queueService.updateNumber(this.form.value.number).subscribe((result: any) => {
        if (result.status.code === 'STATUS_OK') {
          this.queueNumber = result.body.data.activeNumber;
        }
      });
    } else {
      FormUtility.markAllDirty(this.form);
    }
  }
}
