import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<FileUploadComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}
}
