import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { UserDataService } from '../../../services/user-data.service';
import { Observable } from 'rxjs/Observable';
import {StepsDataService} from '../../../services/steps-data.service';
import {GoHome} from '../../../decorators/go-home/go-home';
import {IGoHome} from '../../../decorators/go-home/go-home.interface';

@GoHome
@Injectable()
export class PersonalDataGuard implements CanActivate, IGoHome {
  constructor(
    private _router: Router,
    private _stepsDataService: StepsDataService,
    private _userService: UserDataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this._userService.getCurrentUser()) {
      return true;
    }

    this.goHome();
    return false;
  }

  goHome(): void {}
}
