import {LangType} from './lang.type';
import {BrowserConstant} from './browser.constant';

export class BrowserUser {
  private static _lang: LangType;
  private static _uuid: string;

  private static _token: string;
  private static _email: string;
  private static _anagraficaId: string;

  static setLang(lang: LangType): void {
    if (lang) {
      this._lang = lang;
      localStorage.setItem(BrowserConstant.LocalStorage.lang, this._lang);
    }

    localStorage.setItem(BrowserConstant.LocalStorage.lang, 'it');
  }

  static getLang(): LangType {
    return this._lang || localStorage.getItem(BrowserConstant.LocalStorage.lang) as LangType || 'it';
  }

  static setUuid(uuid: string): void {
    if (uuid) {
      this._uuid = uuid;
      sessionStorage.setItem(BrowserConstant.LocalStorage.uuid, this._uuid);
    }
  }

  static getUuid(): string | undefined {
    return this._uuid || sessionStorage.getItem(BrowserConstant.LocalStorage.uuid);
  }

  static setToken(token: string): void {
    if (token) {
      this._token = token;
      sessionStorage.setItem(BrowserConstant.SessionStorage.token, this._token);
    }
  }

  static getToken(): string | undefined {
    return this._token || sessionStorage.getItem(BrowserConstant.SessionStorage.token);
  }

  static setEmail(email: string): void {
    if (email) {
      this._email = email;
      sessionStorage.setItem(BrowserConstant.SessionStorage.email, this._email);
    }
  }

  static getEmail(): string | undefined {
    return this._email || sessionStorage.getItem(BrowserConstant.SessionStorage.email);
  }

  static setAnagraficaID(anagraficaId: string): void {
    if (anagraficaId) {
      this._anagraficaId = anagraficaId;
      sessionStorage.setItem(BrowserConstant.SessionStorage.anagraficaId, this._anagraficaId);
    }
  }

  static getAnagraficaId(): string | undefined {
    return this._anagraficaId || sessionStorage.getItem(BrowserConstant.SessionStorage.anagraficaId);
  }

  static clearLocalStorage(): void {
    this._lang = undefined;
    this._uuid = undefined;

    localStorage.removeItem(BrowserConstant.LocalStorage.lang);
    localStorage.removeItem(BrowserConstant.LocalStorage.uuid);
  }

  static clearSessionStorage(): void {
    this._token = undefined;
    this._email = undefined;
    this._anagraficaId = undefined;

    sessionStorage.removeItem(BrowserConstant.SessionStorage.token);
    sessionStorage.removeItem(BrowserConstant.SessionStorage.email);
    sessionStorage.removeItem(BrowserConstant.SessionStorage.anagraficaId);
  }
}
