export class PersonalData {
  public id: string;
  public address: string;
  public birthDate: Date;
  public birthPlace: string;
  public birthPlaceFull: string;
  public email: string;
  public fatherhood: string;
  public firstName: string;
  public lastName: string;
  public foreignLanguage: string;
  public gender: string;
  public highSchool: string;
  public highSchoolAvgGrade: string;
  public highSchoolGraduationYear: string;
  public identityCard: string;
  public maritalStatus: string;
  public maritalSurname: string;
  public maturaId: string;
  public mobilePhone: string;
  public motherTongue: string;
  public motherhood: string;
  public nationality: string;
  public otherStudentUsn: string;
  public passport: string;
  public personComplete: boolean;
  public personalNo: string;
  public residence: string;
  public surnameInUse: string;
  public telephone: string;
  public fiscalCode: string;
  public countryOfHighSchool: string;
  public profilePhoto: Blob;

  constructor(initData?: {
    id?: string,
    address?: string,
    birthDate?: Date,
    birthPlace?: string,
    birthPlaceFull?: string,
    email?: string,
    fatherhood?: string,
    firstName?: string,
    lastName?: string,
    foreignLanguage?: string,
    gender?: string,
    highSchool?: string,
    highSchoolAvgGrade?: string,
    identityCard?: string,
    maritalStatus?: string,
    maritalSurname?: string,
    maturaId?: string,
    mobilePhone?: string,
    motherTongue?: string,
    motherhood?: string,
    nationality?: string,
    otherStudentUsn?: string,
    passport?: string,
    personComplete?: boolean,
    personalNo?: string,
    residence?: string,
    surnameInUse?: string,
    telephone?: string,
    fiscalCode?: string,
    countryOfHighSchool?: string,
    highSchoolGraduationYear?: string,
    profilePhoto?: Blob
  }) {
    if (!initData) {
      return;
    }

    this.id = initData.id;
    this.address = initData.address;
    this.birthDate = initData.birthDate;
    this.birthPlace = initData.birthPlace;
    this.birthPlaceFull = initData.birthPlaceFull;
    this.email = initData.email;
    this.fatherhood = initData.fatherhood;
    this.firstName = initData.firstName;
    this.lastName = initData.lastName;
    this.foreignLanguage = initData.foreignLanguage;
    this.gender = initData.gender;
    this.highSchool = initData.highSchool;
    this.highSchoolAvgGrade = initData.highSchoolAvgGrade;
    this.id = initData.id;
    this.identityCard = initData.identityCard;
    this.maritalStatus = initData.maritalStatus;
    this.maritalSurname = initData.maritalSurname;
    this.maturaId = initData.maturaId;
    this.mobilePhone = initData.mobilePhone;
    this.motherTongue = initData.motherTongue;
    this.motherhood = initData.motherhood;
    this.nationality = initData.nationality;
    this.otherStudentUsn = initData.otherStudentUsn;
    this.passport = initData.passport;
    this.personComplete = initData.personComplete;
    this.personalNo = initData.personalNo;
    this.residence = initData.residence;
    this.surnameInUse = initData.surnameInUse;
    this.telephone = initData.telephone;
    this.fiscalCode = initData.fiscalCode;
    this.countryOfHighSchool = initData.countryOfHighSchool;
    this.profilePhoto = initData.profilePhoto;
    this.highSchoolGraduationYear = initData.highSchoolGraduationYear;
  }
}
