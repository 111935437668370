import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StepsDataService} from '../../../services/steps-data.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent {
  constructor(private _stepsDataService: StepsDataService,
              private _translateService: TranslateService) {}

  get title(): string {
    return this._translateService.instant('payment_step.payment');
  }

  get disabledPaymentButton(): boolean {
    if (this._stepsDataService.registration) {
      const {paymentCompleted} = this._stepsDataService.registration;
      return paymentCompleted;
    }

    return false;
  }
}
