import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BrowserUser} from '../browser-user';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private _httpClient: HttpClient) { }

  getNotification() {
    return this._httpClient.get(`${environment.devUrl}/person/${BrowserUser.getAnagraficaId()}/notifications`);
  }

  setNotificationRead(personId, notificationId) {
    return this._httpClient.patch(`${environment.devUrl}/${'person'}/${personId}
    /${'notifications'}/${notificationId}/${'partial/rcvStatus'}`,
      {rcvStatus: 'EMAIL_STAT_READ'}).map((res) => {
      if (res) {
        return true;
      }
    });
  }

  downloadAttachment(notificationId, attachmentId) {
    window.open(`${environment.devUrl}/person/${BrowserUser.getAnagraficaId()}
    /notifications/${notificationId}/${attachmentId}/download`);
  }
}
