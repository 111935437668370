import { Component, OnInit } from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {PreferencesDialogComponent} from '../../../dialogs/preferences-dialog/preferences-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {StepsDataService} from '../../../services/steps-data.service';
import {FormControl, FormGroup} from '@angular/forms';
import {UserDataService} from '../../../services/user-data.service';
import {DialogWarningComponent} from '../../../dialogs/dialog-warning/dialog-warning.component';
import {Preference} from '../../../models/preference';
import {Steps} from '../../enum-steps';
import {AdmissionService} from '../../../services/admission.service';
import {User} from '../../../models/user';
import {TranslateService} from '@ngx-translate/core';
import {BrowserUser} from '../../../models/browser-user';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {
  preferences: Preference[] = [];
  currentUser: User;
  userId: string;
  registrationType: string;

  idForm = new FormGroup({
      id: new FormControl('')
  });

  constructor(public dialog: MatDialog,
              private _router: Router,
              private snack: MatSnackBar,
              private _stepsService: StepsDataService,
              private _activatedRoute: ActivatedRoute,
              private _translateService: TranslateService,
              private _admissionService: AdmissionService,
              private _userData: UserDataService) {}

  ngOnInit() {
    this._stepsService.markStep(Steps.stepThree);
    this.idForm.get('id').setValue(this._userData.personalData.maturaId);

    this._translateService.onLangChange.subscribe(() => {
      this._translateService.getTranslation(BrowserUser.getLang()).subscribe((dictionary) => {
        this._stepsService.markStep(Steps.stepThree);
      });
    });

    this._userData.user$.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser) {
        this.userId = this.currentUser.userAttributes.ADMISSIONS__APP.attributeValue;
        this.getPreferences(this.userId);
      }
    });
    this._activatedRoute.params.subscribe(routeParams => {
      this.registrationType = routeParams.registrationType;
    });
  }

  getPreferences(userId) {
    this._admissionService.getPreferences(userId).subscribe( (res: any) => {
      this.preferences = res.body.data.list;
    });
  }

  removePreference(preference) {
    this._admissionService.deletePreference(this.userId, preference.id).subscribe( (result: any) => {
      this.getPreferences(this.userId);
      if (result.status.code === 'STATUS_OK') {
        this.snack.open('Preference deleted!', '', {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
        });
      } else {
        this.snack.open('Preference ' + result.status.message + '!', '', {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
        });
      }
    });
  }

  disableContinueButton() {
    if (this.preferences.length === 0 || this.idForm.value.id === '') {
      return true;
    }
  }

  continue() {
    this._router.navigate(['dashboard/admission-courses', this.registrationType]);
  }

  openModal() {
    if (this._userData.currentUser) {
      const dialogRef = this.dialog.open(PreferencesDialogComponent, {
        width: '1000px',
        data: {
          userId: this.userId
        }
      });

      dialogRef.afterClosed().subscribe( () => {
        this.getPreferences(this.userId);
      });
    } else {
      this.dialog.open(DialogWarningComponent, {
        width: '600px',
        data: {
          title: 'No Internet Connection',
          message: 'Sorry :( ',
          description: 'We cannot save your data because we lost internet connection'}
      });
    }
  }
}
