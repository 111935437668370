import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-queue-print',
  templateUrl: './queue-print.component.html',
  styleUrls: ['./queue-print.component.css']
})
export class QueuePrintComponent implements OnInit {

  constructor() {
  }

  numbers: any;

  ngOnInit() {
    // this.numbers = Array(100).fill().map((x, i) => i); // [0,1,2,3,4]
  }

}
