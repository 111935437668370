import {NgModule, forwardRef, Injectable} from '@angular/core';
import {AvatarModule} from 'ngx-avatar';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {CollapseModule} from 'angular-modules';
import {HttpStatusInterceptor} from './interceptors/httpStatusInterceptor';
import {NotAuthInterceptor} from './interceptors/notAuthInterceptor';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {routes} from './routes';
import {NgxPayPalModule} from 'ngx-paypal';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatSelectModule,
  MatInputModule,
  MatProgressBarModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatNativeDateModule,
  MatRippleModule,
  MatMenuModule,
  MatDatepickerModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatTabsModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS
} from '@angular/material';

import {StepsDataService} from './services/steps-data.service';
import {UserDataService} from './services/user-data.service';
import {CountriesService} from './services/countries.service';
import {PersonalDataService} from './models/services/personal-data.service';
import {ProfilePhotoService} from './services/profile-photo.service';

import {LoginGuard} from './auth/guards/login.guard';
import {AuthGuard} from './auth/guards/auth.guard';
import {AdmissionCoursesGuard} from './steps/step-three/guards/admission-courses-guard';
import {ProfileCanActivateGuard} from './auth/guards/profile-can-activate.guard';
import {RegistrationTypeGuard} from './steps/step-three/guards/registration-type-guard';
import {PersonalDataGuard} from './steps/step-two/guards/personal-data-guard';
import {PreferencesGuard} from './steps/step-two/guards/preferences-guard';

import {AppComponent} from './app.component';
import {FileUploadComponent} from './dialogs/file-upload/file-upload.component';
import {LoginStepComponent} from './auth/login-step/login-step.component';
import {PaymentComponent} from './steps/step-seven/payment/payment.component';
import {PersonalDataComponent} from './steps/step-two/personal-data/personal-data.component';
import {PortalPrivacyComponent} from './dialogs/portal-privacy/portal-privacy.component';
import {ProfilePageComponent} from './steps/step-one/profile-page/profile-page.component';
import {PreferencesDialogComponent} from './dialogs/preferences-dialog/preferences-dialog.component';
import {PreferencesComponent} from './steps/step-two/preferences/preferences.component';
import {ProfileDataDialogComponent} from './dialogs/profile-data-dialog/profile-data-dialog.component';
import {ConfirmationComponent} from './steps/step-six/confirmation/confirmation.component';
import {DialogWarningComponent} from './dialogs/dialog-warning/dialog-warning.component';
import {ForgotPasswordComponent} from './auth/forgot-password-step/forgot-password/forgot-password.component';
import {AdmissionCoursesComponent} from './steps/step-three/admission-courses/admission-courses.component';
import {AdmissionDocumentsComponent} from './steps/step-four/admission-documents/admission-documents.component';
import {DialogNotificationsComponent} from './dialogs/dialog-notifications/dialog-notifications.component';
import {DialogDeleteComponent} from './dialogs/dialog-delete/dialog-delete.component';
import {SummaryDialogComponent} from './dialogs/summary-dialog/summary-dialog.component';
import {ResultComponent} from './steps/step-eight/result/result.component';
import {RegisterStepComponent} from './auth/register-step/register-step.component';
import {RegistrationTypeComponent} from './steps/step-three/registration-type/registration-type.component';
import {TermsConditionsComponent} from './dialogs/terms-conditions/terms-conditions.component';
import {SimilarAccountsDialogComponent} from './dialogs/similar-accounts-dialog/similar-accounts-dialog.component';
import {PasswordStepComponent} from './auth/password-step/password-step.component';
import {DialogSuccessComponent} from './dialogs/dialog-success/dialog-success.component';
import {DialogInfoPassportComponent} from './dialogs/dialog-info-passport/dialog-info-passport.component';
import {DialogInfoIdCardComponent} from './dialogs/dialog-info-id-card/dialog-info-id-card.component';
import {DialogInfoPersonalNoComponent} from './dialogs/dialog-info-personal-no/dialog-info-personal-no.component';
import {AuthUserComponent} from './auth/forgot-password-step/auth-user/auth-user.component';
import {DialogInfoPhotoComponent} from './dialogs/dialog-info-photo/dialog-info-photo.component';
import {AppHeaderComponent} from './app-header/app-header.component';
import { QueuePanelComponent } from './steps/step-one/queue-panel/queue-panel.component';
import { QueueComponent } from './steps/step-two/queue/queue.component';
import { QueuePrintComponent } from './steps/step-two/queue-print/queue-print.component';
import { AuthWrapperComponent } from './auth/auth-wrapper.component';
import { StepsComponent } from './steps/steps.component';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import { PrepaymentComponent } from './steps/step-five/prepayment/prepayment.component';
import { InvoiceComponent } from './invoice/invoice.component';

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent,
    RegisterStepComponent,
    LoginStepComponent,
    ForgotPasswordComponent,
    ProfilePageComponent,
    PersonalDataComponent,
    DialogWarningComponent,
    RegistrationTypeComponent,
    PreferencesComponent,
    TermsConditionsComponent,
    PortalPrivacyComponent,
    PreferencesDialogComponent,
    AdmissionCoursesComponent,
    AdmissionDocumentsComponent,
    FileUploadComponent,
    ConfirmationComponent,
    PaymentComponent,
    ResultComponent,
    DialogDeleteComponent,
    DialogNotificationsComponent,
    SummaryDialogComponent,
    ProfileDataDialogComponent,
    SimilarAccountsDialogComponent,
    PasswordStepComponent,
    DialogSuccessComponent,
    DialogInfoPassportComponent,
    DialogInfoIdCardComponent,
    DialogInfoPersonalNoComponent,
    AuthUserComponent,
    DialogInfoPhotoComponent,
    AppHeaderComponent,
    QueuePanelComponent,
    QueueComponent,
    QueuePrintComponent,
    AuthWrapperComponent,
    StepsComponent,
    PrepaymentComponent,
    InvoiceComponent
  ],
  imports: [
    AngularFontAwesomeModule,
    AvatarModule,
    BrowserAnimationsModule,
    BrowserModule,
    CollapseModule,
    HttpClientModule,
    FormsModule,
    MatDialogModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    MatRippleModule,
    MatDatepickerModule,
    MatTabsModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatRadioModule,
    MatIconModule,
    NgxPayPalModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    CountriesService,

    StepsDataService,
    UserDataService,

    AuthGuard,
    LoginGuard,
    ProfileCanActivateGuard,
    PersonalDataGuard,
    PreferencesGuard,
    RegistrationTypeGuard,
    AdmissionCoursesGuard,

    PersonalDataService,
    ProfilePhotoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpStatusInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotAuthInterceptor,
      multi: true
    },
    [
      Location,
      {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      }
    ],
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'it'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    }
  ],
  entryComponents: [
    DialogWarningComponent,
    DialogSuccessComponent,
    DialogDeleteComponent,
    TermsConditionsComponent,
    PortalPrivacyComponent,
    DialogInfoPersonalNoComponent,
    DialogInfoPassportComponent,
    DialogInfoPhotoComponent,
    DialogInfoIdCardComponent,
    PreferencesDialogComponent,
    DialogNotificationsComponent,
    FileUploadComponent,
    ProfileDataDialogComponent,
    SummaryDialogComponent,
    SimilarAccountsDialogComponent
  ],
  bootstrap: [
    AppComponent
  ]
})

@Injectable()
export class AppModule {}
