import {Component, OnDestroy, OnInit} from '@angular/core';
import {StepsDataService} from '../services/steps-data.service';
import {TranslateService} from '@ngx-translate/core';
import {UserDataService} from '../services/user-data.service';
import {User} from '../models/user';
import {Steps as StepEnum} from './enum-steps';
import {StepModel} from '../models/step.model';
import {BrowserUser} from '../models/browser-user';
import {ActivatedRoute} from '@angular/router';
import {PersonalData} from '../models/personal-data';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css']
})
export class StepsComponent implements OnInit, OnDestroy {
  steps: StepModel[];
  currentUser: User;

  constructor(private _activatedRoute: ActivatedRoute,
              private _stepsDataService: StepsDataService,
              private _translateService: TranslateService,
              private _userData: UserDataService) {

    BrowserUser.setLang('it');
    this._translateService.setDefaultLang(BrowserUser.getLang());
    this._translateService.use(BrowserUser.getLang());
  }

  ngOnInit() {
    this._userData.checkGetUser$();

    this._stepsDataService
      .getSteps()
      .subscribe(steps => this.steps = steps);

    this._stepsDataService
      .getStepsOnLangChange()
      .subscribe(res => this.steps = res);

    this._userData
      .user$
      .subscribe((user: User) => this.currentUser = user);
  }

  ngOnDestroy(): void {
    this._stepsDataService.clearStepDataService();
  }

  get personData(): PersonalData {
    return this._activatedRoute.snapshot.data.personData;
  }

  navigateToStep(step: StepEnum): void {
    this._stepsDataService.navigateToStep(step);
  }
}
