import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BrowserUser} from '../models/browser-user';


@Injectable({
  providedIn: 'root'
})

export class AdmissionService {
  private readonly baseUrl: string;

  constructor(private _httpClient: HttpClient) {
    this.baseUrl = `${environment.devUrl}/person/${BrowserUser.getAnagraficaId()}`;
  }

  addQSHAPreference(personId, mashDegreeId) {
    return this._httpClient.post(`${environment.devUrl}/person/${personId}/externalIALs/new`,
      {personId: personId, mashDegreeId: mashDegreeId});
  }

  deletePreference(personId, mashDegreeId) {
    return this._httpClient.delete(`${environment.devUrl}/person/${personId}/externalIALs/${mashDegreeId}`);
  }

  getUniversitiesTree() {
    return this._httpClient.get(`${environment.devUrl}/mash/universities`);
  }

  getPossibleExams(personId, registrationType) {
    const params: any = {
      paramBean: JSON.stringify({
        fillFieldLabels: true,
        targetRegistrationType: registrationType
      })
    };
    return this._httpClient.get(`${environment.devUrl}/person/${personId}/possibleExams`, {params: params});
  }

  getPreferences(personId) {
    return this._httpClient.get(`${environment.devUrl}/person/${personId}/externalIALs`);
  }

  registerExam(personId, examId, registrationType) {
    return this._httpClient.post(`${environment.devUrl}/person/${personId}/admissionRegistrations/new`,
      {examId: examId, registrationType: registrationType});
  }

  getDocumentsList(registrationId) {
    return this._httpClient.get(`${this.baseUrl}/admissionRegistrations/${registrationId}/documents`);
  }

  uploadDocument(registrationId, documentId, file) {
    const fd: FormData = new FormData();
    fd.append('attachment', file, file.name);
    return this._httpClient.post(`${this.baseUrl}/admissionRegistrations/${registrationId}/documents/${documentId}/upload`, fd);
  }

  // noinspection JSMethodCanBeStatic
  downloadDocument(registrationId, documentId) {
    window.open(
      `${this.baseUrl}/admissionRegistrations/${registrationId}/documents/${documentId}/download`,
      '_blank'
    );
  }

  deleteDocument(registrationId, documentId) {
    return this._httpClient.delete(`${this.baseUrl}/admissionRegistrations/${registrationId}/documents/${documentId}`);
  }
}
