import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserDataService} from '../../services/user-data.service';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';

@Injectable()
export class LoginGuard implements CanActivate {
  guard$ = new Subject<boolean>();

  constructor(private _route: Router,
              private _userService: UserDataService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._userService.getCurrentUser()) {
      return false;
    } else {
      return true;
    }

    // if (this._userService.getCurrentUser()) {
    //   return false;
    // } else {
    //   this._userService.checkGetUser$()
    //     .do((userExists) => {
    //       if (userExists) {
    //         this.guard$.next(false);
    //         this._route.navigate(['./profile']);
    //       }
    //     }).map((res: any) => {
    //     return !res.body && !res.body.data === undefined;
    //   }).catch((err) => {
    //     if (err.status === 401) {
    //       this.guard$.next(true);
    //       return Observable.throwError('Unauthenticated');
    //     }
    //   }).subscribe();
    //
    //   return this.guard$.asObservable();
    // }
  }
}
