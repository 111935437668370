import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Registration} from '../registration';
import {environment} from '../../../environments/environment';
import {UserDataService} from '../../services/user-data.service';
import {Observable} from 'rxjs/Rx';
import {BrowserUser} from '../browser-user';
import {filter, map, tap} from 'rxjs/operators';
import {ResponseModel} from '../response.model';
import {Invoice} from '../invoice';
import {isStatusOk} from '../../functions/response-status/is-status-ok';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(private _httpClient: HttpClient,
              private _userService: UserDataService) { }

  cancelSubmition(personId, registrationId) {
    return this._httpClient.delete(`${environment.devUrl}/person/${personId}/admissionRegistrations/${registrationId}`);
  }

  getRegistrations() {
    const reqOptions = {
      params: new HttpParams().set('paramBean', JSON.stringify({fillFieldLabels: true}))
    };

    return this._httpClient.get(`${environment.devUrl}/person/${BrowserUser.getAnagraficaId()}/admissionRegistrations`, reqOptions);
  }

  getRegistration(registrationId: string): Observable<Registration | undefined> {
    const params: HttpParams = new HttpParams().set('paramBean', JSON.stringify({fillFieldLabels: true}));

    return this._httpClient
      .get<any>(`${environment.devUrl}/person/${BrowserUser.getAnagraficaId()}/admissionRegistrations/${registrationId}`, {params})
      .pipe(
        map(res => {
          if (res.status.code === 'STATUS_OK') {
            return res.body.data;
          }

          return undefined;
        }),
        tap(registration => this._userService.registration = registration)
      );
  }

  getRegistration$(registrationId) {
    const reqOptions = {
      params: new HttpParams().set('paramBean', JSON.stringify({'fillFieldLabels': true}))
    };

    return this._httpClient
      .get(`${environment.devUrl}/person/${BrowserUser.getAnagraficaId()}/admissionRegistrations/${registrationId}`, reqOptions)
      .do((res: any) => {
        this._userService.registration = res.body.data;
      });
  }

  // saveRegistration$(registration: Registration) {
  //   return this._httpClient.post(`${'api/registrations'}`, registration);
  // }
  //
  // addRegistration(registration) {
  //   return this._httpClient.post('api/registrations', registration);
  // }

  getPaymentInvoice(registrationId: string, invoiceId: string): Observable<Invoice> {
    const params: HttpParams = new HttpParams().set('paramBean', JSON.stringify({fillFieldLabels: true}));

    return this._httpClient
      .get<ResponseModel<Invoice>>(
        `${environment.devUrl}/person/${BrowserUser.getAnagraficaId()}/admissionRegistrations/${registrationId}/invoice/${invoiceId}`,
        { params }
      )
      .pipe(
        filter(res => isStatusOk(res)),
        map(res => res.body.data)
      );
  }

  payPaymentInvoice(registrationId: string, invoiceId: string): Observable<any> {
    return this._httpClient
      .get<any>(
        `${environment.devUrl}/person/${BrowserUser.getAnagraficaId()}/admissionRegistrations/${registrationId}/invoice/${invoiceId}/pay`,
      );
  }

  paymentConfirmation(registrationId, invoiceId, data) {
    // noinspection TsLint
    return this._httpClient
      .put(
        `${environment.devUrl}/${'person'}/${BrowserUser.getAnagraficaId()}/admissionRegistrations/${registrationId}/invoice/${invoiceId}`,
        {id: invoiceId, paypalAuthorize: data}
      );
  }
}
