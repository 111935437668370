import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserDataService} from '../../../services/user-data.service';
import {BrowserUser} from '../../../models/browser-user';

@Component({
  selector: 'app-auth-user',
  templateUrl: './auth-user.component.html',
  styleUrls: ['./auth-user.component.css']
})
export class AuthUserComponent implements OnInit {

  routeParams: any;

  loginForm = new FormGroup({
    username: new FormControl('', [ Validators.required, Validators.minLength(5),
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
  });

  constructor(private _router: Router,
              private _snack: MatSnackBar,
              private _activatedRoute: ActivatedRoute,
              private _userDataService: UserDataService) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      this.routeParams = params;
    });
  }

  submit() {
    if (this.loginForm.valid) {
      this._userDataService.loginUuid(this.loginForm.value.username).subscribe((result: any) => {
        if (result.status.code === 'STATUS_OK') {
          BrowserUser.setUuid(result.body.data.serviceUuid);
          this._router.navigate(['auth/reset-password', this.routeParams.id]);
        } else {
          this._snack.open(
            result.status.message,
            '', {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            }
          );
          this._userDataService.logout();
        }
      });
    }
  }

}
