import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {UserDataService} from '../services/user-data.service';

@Injectable()
export class NotAuthInterceptor implements HttpInterceptor {

  constructor(private snack: MatSnackBar,
              private router: Router,
              private userService: UserDataService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.status === 200) {
            if (event.body.status) {
              if (event.body.status.code === 'BAD_REQUEST') {
                // this.snack.open(event.body.status.message, 'DISMISS', {duration: 3000, horizontalPosition: 'right'});
              } else if (event.body.status.code === 'NO_CONTENT') {
                // this.snack.open(event.body.status.message, 'DISMISS', {duration: 3000, horizontalPosition: 'right'});
              } else if (event.body.status.code === 'WARNING') {
                // this.snack.open(event.body.status.message, 'DISMISS', {duration: 3000, horizontalPosition: 'right'});
              } else if (event.body.status.code === 'EXPECTATION_FAILED') {
                // this.snack.open(event.body.status.message, 'DISMISS', {duration: 3000, horizontalPosition: 'right'});
              }
            }
          }
        }
        return event;
      }), catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.userService.autoLogout();
        } else if (error.status === 403) {
          this.snack.open(error.error.status ? error.error.status.message : 'ERROR', 'DISMISS',
            {duration: 5000, horizontalPosition: 'end'});
        } else if (error.status === 404) {
          this.snack.open(`${error.status} ${error.statusText}`, 'DISMISS',
            {duration: 5000, horizontalPosition: 'end'});
        } else if (error.status === 500) {
          this.snack.open(error.error.status ? error.error.status.message : 'ERROR',
            'DISMISS', {duration: 5000, horizontalPosition: 'end'});
        } else if (error.status === 503) {
          this.snack.open(error.error.status ? error.error.status.message : 'ERROR',
            'DISMISS', {duration: 5000, horizontalPosition: 'end'});
        } else {
          this.snack.open(`${error.status} ${error.statusText}`, 'DISMISS',
            {duration: 5000, horizontalPosition: 'end'});
        }
        return throwError(error);
      })
    );
  }

}
