import {Steps} from '../steps/enum-steps';
import {StepGuardEnum} from '../steps/guards/step-guard.enum';
import {StepType} from './step.type';

export interface IStep {
  readonly step: Steps;
  readonly stepGuard: StepGuardEnum;
  readonly title: string;
  readonly description?: string;
  readonly stepType?: StepType;
  readonly route?: string[];
}

export class StepModel {
  step: Steps;
  stepGuard: StepGuardEnum;

  title: string;
  description: string;
  stepType: StepType;

  route: string[];

  constructor({step, stepGuard, title, description, stepType, route}: IStep) {
    this.step = step;
    this.stepGuard = stepGuard;

    this.title = title;
    this.description = description || '';
    this.stepType = stepType || 'pending';

    this.route = route || [];
  }

  navigateTo(...path: string[]): string[] {
    if (path) {
      return ['dashboard', ...this.route, ...path];
    }

    return ['dashboard', ...this.route];
  }
}
