import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {UserDataService} from "../../services/user-data.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {DialogSuccessComponent} from "../dialog-success/dialog-success.component";
import {BrowserUser} from '../../models/browser-user';

@Component({
  selector: 'app-similar-accounts-dialog',
  templateUrl: './similar-accounts-dialog.component.html',
  styleUrls: ['./similar-accounts-dialog.component.css']
})
export class SimilarAccountsDialogComponent implements OnInit {
  dictionary: any;
  constructor(public dialogRef: MatDialogRef<SimilarAccountsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private userData: UserDataService,
              private dialog: MatDialog,
              private _translateService: TranslateService,
              private _snack: MatSnackBar,
              private _router: Router) { }

  ngOnInit() {
    this._translateService.getTranslation(BrowserUser.getLang()).subscribe((dictionary) => {
      this.dictionary = dictionary;
    });
  }

  close() {
    this.dialogRef.close();
  }

  noneOfAbove(person) {
    const tempUser = {
      firstName : person.firstName,
      lastName : person.lastName,
      email : person.email,
      personalNo: this.data.person.personalNo,
      confirmPersonId : 'ANAG_000000000X',
      lang: BrowserUser.getLang()
    };

    this.userData.signup(tempUser).subscribe((res: any) => {
      if (res.status.code === 'STATUS_OK') {
        this.userData.loginUser(res.body.data);
        this.successModal(res.status.message);
        this.userData.loginUuid(tempUser.email).subscribe( (result: any) => {
          if (result.status.code === 'STATUS_OK') {
            this._router.navigate(['dashboard/profile']);
          }
        });
        this.close();
      } else {
        this._snack.open(
          res.status.message,
          '', {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          }
        );
        this._router.navigate(['auth/signin']);
      }
    });
  }

  signUpExistingUser(person, user) {
    const tempUser = {
      firstName : user.firstName,
      lastName : user.lastName,
      email : user.email,
      personalNo: this.data.person.personalNo,
      confirmPersonId : person.id,
      lang: BrowserUser.getLang()
    };

    this.userData.signup(tempUser).subscribe((res: any) => {
      if (res.status.code === 'STATUS_OK') {
        this.userData.loginUser(res.body.data);
        this.successModal(res.status.message);
        this.userData.loginUuid(user.email).subscribe( (result: any) => {
          if (result.status.code === 'STATUS_OK') {
            this._router.navigate(['dashboard/profile']);
          }
        });
        this.close();
      } else {
        this._snack.open(
          res.status.message,
          '', {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
          }
        );
        this._router.navigate(['auth/signin']);
      }
    });
  }

  successModal(message) {
    this.dialog.open(DialogSuccessComponent, {
      width: '600px',
      data: {
        title: this.dictionary.global.success,
        message: message
      }
    });
  }
}
