import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {PersonalData} from '../models/personal-data';
import {ActivatedRouteSnapshot} from '@angular/router/src/router_state';

@Injectable({
  providedIn: 'root'
})
export class ParentPersonDataResolver implements Resolve<PersonalData> {
  resolve(route: ActivatedRouteSnapshot): PersonalData {
    return route.parent.data.personData;
  }
}
