import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {StepsDataService} from '../services/steps-data.service';
import {RegistrationService} from '../models/services/registration.service';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {GoHome} from '../decorators/go-home/go-home';
import {IGoHome} from '../decorators/go-home/go-home.interface';

@GoHome
@Injectable({
  providedIn: 'root'
})
export class ActivateRegistrationGuard implements CanActivate, IGoHome {
  constructor(private _router: Router,
              private _stepsDataService: StepsDataService,
              private _registrationService: RegistrationService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (route.paramMap.has('id')) {
      return this._registrationService
        .getRegistration(route.paramMap.get('id') as string)
        .pipe(
          map(registration => {
            if (
              registration &&
              (
                route.data.stepComplete ?
                  route.data.stepComplete(registration) :
                  true
              )
            ) {
              this._stepsDataService.registration = registration;
              this._stepsDataService.markStep(route.data.step);
              return true;
            }

            this.goHome();
            return false;
          })
        );
    }

    this.goHome();
    return false;
  }

  goHome(): void {}
}
