import {Router} from '@angular/router';
import {StepsDataService} from '../../services/steps-data.service';
import {Steps} from '../../steps/enum-steps';

export function GoHome<T extends { new (...args: any[]) }>(constructor: T): any {
  return class extends constructor {
    goHome(): void {
      const args: any[] = Object.values(this);

      const router: Router = args.find(arg => arg instanceof Router);
      const stepsDataService: StepsDataService = args.find(arg => arg instanceof StepsDataService);

      if (router) {
        router
          .navigateByUrl('/dashboard/profile')
          .then(() => {
            if (stepsDataService) {
              stepsDataService.markStep(Steps.stepOne);
            }
          });
      }
    }
  };
}
