export class Registration {
  avgGradeCheckCompletedLastUpdate: string;
  lastName: string;
  cancelDate: string;
  personalDataLastOperator: string;
  notes: string;
  examDate: string;
  paymentCompletedLastUpdate: string;
  prepaymentCompleted: boolean;
  avgGradeRequiredDocumentsAccepted: boolean;
  prepaymentCompletedLastOperator: string;
  assignedTo: string;
  avgGradeCheckValid: boolean;
  academicYear: string;
  avgGradeCheckCompleted: boolean;
  registrationsEnd: string;
  paymentCompletedLastOperator: string;
  registrationDate: string;
  prepaymentInvoiceId: string;
  id: string;
  avgGradeCheckCompletedLastOperator: string;
  cancelReason: string;
  courseId: string;
  prepaymentCompletedLastUpdate: string;
  paymentCompleted: boolean;
  allRequiredDocumentsAccepted: boolean;
  targetStudents: string;
  regActionDate: string;
  examName: string;
  documentsCheckCompletedLastOperator: string;
  canceled: boolean;
  firstName: string;
  personalDataLastUpdate: string;
  documentsCheckCompletedLastUpdate: string;
  documentsCheckCompleted: boolean;
  examId: string;
  personalDataCompleted: boolean;
  personId: string;
  step: string;
  paymentInvoiceId: string;
  allRequiredDocumentsUploaded: boolean;
  documentsEnd: string;
  someLabel: string;
  status: string;

  labelMap?: any;
}
