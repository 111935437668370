import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {UserDataService} from '../../../services/user-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {BrowserUser} from '../../../models/browser-user';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  inputType = 'password';
  inputType2 = 'password';
  routeParams: any;
  langData: any;
  loginForm = new FormGroup({
    password : new FormControl(),
    confirmPassword : new FormControl()
  });

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private snack: MatSnackBar,
              private _translate: TranslateService,
              private _userService: UserDataService) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      this.routeParams = params;
    });
    this._translate.getTranslation(BrowserUser.getLang()).subscribe((langData) => {
      this.langData = langData;
    });
  }

  goBack() {
    this._userService.logout();
    this._router.navigate(['auth/login']);
  }

  tooglePassword() {
    if (this.inputType === 'text') {
      this.inputType = 'password';
    } else {
      this.inputType = 'text';
    }
  }

  toogleConfirmPassword() {
    if (this.inputType2 === 'text') {
      this.inputType2 = 'password';
    } else {
      this.inputType2 = 'text';
    }
  }

  reset() {
    if (this.loginForm.value.password === this.loginForm.value.confirmPassword) {
      this._userService.resetPassword(this.routeParams.id, this.loginForm.value.confirmPassword).subscribe((result: any) => {
        if (result.status.code === 'STATUS_OK') {
          this._userService.logout();
          this.snack.open(
            this.langData.reset_password.success_message,
            '', {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            }
          );
        } else {
          this.snack.open(
            result.status.message,
            '', {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            }
          );
          this._router.navigate(['auth/forgot-password', this.routeParams.id]);
        }
      });
    } else {
      this.snack.open(
        this.langData.reset_password.password_not_match,
        '', {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
        }
      );
    }
  }

}
