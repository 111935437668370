
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {HttpClient} from '@angular/common/http';
import {BrowserUser} from '../models/browser-user';

@Injectable({
  providedIn: 'root'
})
export class ProfilePhotoService {
  private _photoSrc: any;
  _profilePhoto$ = new Subject<string>();

  // _profilePhoto$ = new Subject();

  constructor() {
    this._profilePhoto$.next('anag/' + BrowserUser.getAnagraficaId() + '.jpg');
  }

  get photoSrc(): any {
    return this._photoSrc;
  }

  set photoSrc(value: any) {
    this._photoSrc = value;
  }
}
