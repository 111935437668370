import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BrowserUser} from '../models/browser-user';

@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const userToken = BrowserUser.getToken();
    const lang = BrowserUser.getLang();

    const headers = {
      'Authorization': userToken,
      'Accept-Language': lang,
      'Accept': 'application/json'
    };

    const unAuthHeaders = {
      'Accept-Language': lang,
      'Accept': 'application/json'
    };

    if (userToken) {
      const cloned = req.clone({
        setHeaders: headers
      });

      return next.handle(cloned);

    } else {
      const cloned = req.clone({
        setHeaders: unAuthHeaders
      });

      return next.handle(cloned);
    }
  }
}
