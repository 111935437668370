import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {CountriesService} from '../../../services/countries.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {UserDataService} from '../../../services/user-data.service';
import {PortalPrivacyComponent} from '../../../dialogs/portal-privacy/portal-privacy.component';
import {StepsDataService} from '../../../services/steps-data.service';
import {TermsConditionsComponent} from '../../../dialogs/terms-conditions/terms-conditions.component';
import {FormUtility} from '../../../utitlities/FormUtilities';
import {User} from '../../../models/user';
import {PersonalData} from '../../../models/personal-data';
import {PersonalDataService} from '../../../models/services/personal-data.service';
import {ProfilePhotoService} from '../../../services/profile-photo.service';
import {Steps} from '../../enum-steps';
import {DialogInfoPassportComponent} from '../../../dialogs/dialog-info-passport/dialog-info-passport.component';
import {DialogInfoIdCardComponent} from '../../../dialogs/dialog-info-id-card/dialog-info-id-card.component';
import {DialogInfoPersonalNoComponent} from '../../../dialogs/dialog-info-personal-no/dialog-info-personal-no.component';
import {DialogInfoPhotoComponent} from '../../../dialogs/dialog-info-photo/dialog-info-photo.component';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {AvatarComponent} from 'ngx-avatar';
import {BrowserUser} from '../../../models/browser-user';
import {GraduationYearConstant} from '../../../models/graduation-year.constant';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.css']
})
export class PersonalDataComponent implements OnInit, Validators {
  @Input('context')
    context = 'view';

  @Output()
    personalDataSaveEvent = new EventEmitter();

  @ViewChild('avatar')
    avatar: AvatarComponent;

  @ViewChild('uploader')
    uploader;

  @ViewChild('avatar')
  set avatarChild(avatar: AvatarComponent) {
    if (avatar) {
      this.avatar = avatar;
    }
  }

  maxDate = new Date();
  fileData: File = null;
  countries: CountryList[];
  currentUser: User;
  personalData: PersonalData = new PersonalData();
  languagesList: string[] = ['Albanian', 'English', 'Italian', 'Greek', 'French', 'Other'];
  readonly graduationYears: string[] = GraduationYearConstant();
  tempPhotoSrc: any;
  photoUploaded = false;
  foreignLanguagesFc = new FormControl();

  personalDataForm = this._fb.group({
    address: ['', Validators.required],
    birthDate: ['', Validators.required],
    birthPlace: [''],
    countryOfHighSchool: ['', Validators.required],
    email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
    fatherhood: ['', [Validators.required,  Validators.minLength(3)]],
    foreignLanguage: [''],
    firstName: [{value: '', disabled: true}, Validators.required],
    gender: ['', Validators.required],
    highSchool: ['', [Validators.required,  Validators.minLength(5)]],
    highSchoolGraduationYear: ['', Validators.required],
    lastName: [{value: '', disabled: true}, [Validators.required, Validators.minLength(3)]],
    motherhood: ['', [Validators.required, Validators.minLength(3)]],
    motherTongue: ['', Validators.required],
    mobilePhone: ['', [Validators.required, Validators.minLength(9)]],
    nationality: ['', Validators.required],
    personalNo: ['', Validators.required],

    identityCard: [''],
    passport: [''],
    residencePermit: [''],
    telephone: [''],
    termsAgreement: ['']
  });

  dictionary: any;
  userId: string;

  constructor(
    public dialog: MatDialog,
    private _countriesService: CountriesService,
    private _fb: FormBuilder,
    private _router: Router,
    private _steps: StepsDataService,
    private _userData: UserDataService,
    private _personalData: PersonalDataService,
    private _snack: MatSnackBar,
    private _translateService: TranslateService,
    private _profilePhoto: ProfilePhotoService
  ) {}

  ngOnInit() {
    setTimeout(() => this.avatar.src = this._profilePhoto.photoSrc || `anag/${BrowserUser.getAnagraficaId()}.jpg`);

    this._translateService
      .onLangChange
      .subscribe((translation: LangChangeEvent) => {
        this.dictionary = translation.translations;
      });

    this._translateService
      .getTranslation(BrowserUser.getLang())
      .subscribe((dictionary) => {
        this.dictionary = dictionary;
      });

    this.maxDate.setFullYear( this.maxDate.getFullYear() - 13);

    this._userData
      .user$
      .subscribe((user: User) => {
        if (this.context === 'view') {
          this._steps.markStep(Steps.stepTwo);
        }

        this.currentUser = user;
        this.userId = this.currentUser ?
          this.currentUser.userAttributes.ADMISSIONS__APP.attributeValue :
          BrowserUser.getAnagraficaId();

        setTimeout(() => {
          if (this.avatar) {
            this.avatar.src = 'anag/' + this.userId + '.jpg';
          }
        });

        const fullNameArray = this._userData.currentUser.fullName.split(' ');

        this.personalDataForm.get('firstName').setValue(fullNameArray[0]);
        this.personalDataForm.get('lastName').setValue(fullNameArray[1]);
        this.personalDataForm.get('email').setValue(this.currentUser.username);

        this._personalData
          .getUserData(this.userId)
          .subscribe((result: any) => {
            this.personalData = result.body.data;

            this.personalDataForm.get('address').setValue(this.personalData.address);
            this.personalDataForm.get('birthDate').setValue(this.personalData.birthDate);
            this.personalDataForm.get('birthPlace').setValue(this.personalData.birthPlace);
            this.personalDataForm.get('countryOfHighSchool').setValue(this.personalData.countryOfHighSchool);
            this.personalDataForm.get('fatherhood').setValue(this.personalData.fatherhood);
            this.personalDataForm.get('foreignLanguage').setValue(this.personalData.foreignLanguage);

            if (this.personalData.foreignLanguage) {
              this.foreignLanguagesFc.setValue(this.personalData.foreignLanguage.split(','));
            }

            this.foreignLanguagesFc.markAsPristine();
            this.personalDataForm.get('gender').setValue(this.personalData.gender);
            this.personalDataForm.get('highSchool').setValue(this.personalData.highSchool);
            this.personalDataForm.get('highSchoolGraduationYear').setValue(this.personalData.highSchoolGraduationYear);
            this.personalDataForm.get('identityCard').setValue(this.personalData.identityCard);
            this.personalDataForm.get('motherhood').setValue(this.personalData.motherhood);
            this.personalDataForm.get('mobilePhone').setValue(this.personalData.mobilePhone);
            this.personalDataForm.get('motherTongue').setValue(this.personalData.motherTongue);
            this.personalDataForm.get('nationality').setValue(this.personalData.nationality);
            this.personalDataForm.get('passport').setValue(this.personalData.passport);
            this.personalDataForm.get('personalNo').setValue(this.personalData.personalNo);
            this.personalDataForm.get('residencePermit').setValue(this.personalData.residence);
            this.personalDataForm.get('telephone').setValue(this.personalData.telephone);
            this.personalDataForm.get('firstName').setValue(this.personalData.firstName);
            this.personalDataForm.get('lastName').setValue(this.personalData.lastName);
          });
      });

    this.getCountries();
  }

  getCountries() {
    this._personalData
      .getCountries()
      .subscribe( (result: any) => this.countries = result.body.data.list);
  }

  privacyModal() {
    this.dialog.open(PortalPrivacyComponent, {
      width: '800px'
    });
  }

  close() {
    this.dialog.closeAll();
  }

  savePersonalData() {
    const {value: foreignLanguages} = this.foreignLanguagesFc;
    this.personalDataForm.get('foreignLanguage').setValue(foreignLanguages ? foreignLanguages.toString() : undefined);

    if (!this.personalDataForm.get('identityCard').value && !this.personalDataForm.get('passport').value) {
      this.personalDataForm.get('passport').setErrors({'required': true});
      this.personalDataForm.get('identityCard').setErrors({'required': true});

    } else if (!this.personalDataForm.get('identityCard').value || this.personalDataForm.get('passport').value) {
      this.personalDataForm.get('identityCard').setValue(this.personalDataForm.get('identityCard').value);
      this.personalDataForm.get('passport').setValue(this.personalDataForm.get('passport').value);

    } else if (this.personalDataForm.get('identityCard').value || !this.personalDataForm.get('passport').value) {
      this.personalDataForm.get('identityCard').setValue(this.personalDataForm.get('identityCard').value);
      this.personalDataForm.get('passport').setValue(this.personalDataForm.get('passport').value);
    }

    if (this.personalDataForm.valid) {
      this.personalData =  new PersonalData(this.personalDataForm.getRawValue());
      this.personalData.id = this.userId;

      const fixDate = new Date(this.personalData.birthDate);
      fixDate.setHours(fixDate.getHours() + 20);
      this.personalData.birthDate = fixDate;

      this._userData
        .updatePersonalData(this.userId, this.personalData)
        .subscribe( (result: any) => {
          if (result.status.code === 'STATUS_OK') {
            this._personalData._updateProfile$.next('true');

            if (this.context === 'view') {

              this._router.navigate(['dashboard/registration-type']).then();

            } else if (this.context === 'dialog') {

              if (this.tempPhotoSrc) {
                this._profilePhoto.photoSrc = this.tempPhotoSrc;
              }

              this.personalDataSaveEvent.emit(this.tempPhotoSrc);
            }

            this._snack
              .open(this.dictionary.personal_data_step.personal_data_updated, null, {
                duration: 5000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
              });

            this._userData.personalData.personComplete = true;
          } else {
            this._snack
              .open(result.status.message, null, {
                duration: 5000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
              });
          }
        });
    } else {
      FormUtility.markAllDirty(this.personalDataForm);
    }
  }

  termsModal() {
    this.dialog.open(TermsConditionsComponent, {
      width: '700px'
    });
  }

  uploadPhoto(event) {
    if (event) {
      const reader = new FileReader();
      this.fileData = <File>event.target.files[0];


      reader.onload = (e) => {
        this.avatar.src = e.target['result'];
        this._profilePhoto.photoSrc = this.avatar.src;
      };

      const formData = new FormData();
      formData.append('attachment', this.fileData, this.fileData.name);
      reader.readAsDataURL(this.fileData);

      this._personalData.updatePersonalPhoto(this.personalData.id, formData).subscribe( (result: any) => {
        if (result.status.code === 'STATUS_OK') {
          this.photoUploaded = true;
          this._profilePhoto._profilePhoto$.next(this.avatar.src);
          this._snack.open(
            this.dictionary.personal_data_step.photo_changed,
            '', {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            }
          );
        } else {
          this.avatar.src = 'anag/' + BrowserUser.getAnagraficaId() + '.jpg';
          this._profilePhoto.photoSrc = undefined;
          this._snack.open(
            result.status.message,
            result.status.code, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            }
          );
        }
      });
    }
  }

  openPhotoHelp() {
    this.dialog.open(DialogInfoPhotoComponent, {
      width: '700px'
    });
    // this.dialog.open(FaceRecognitionComponent, { // TODO: to be replaced with the commented part
    //   width: '960px',
    //   data: {
    //     personId: this.personalData.id,
    //     firstName: this.personalData.firstName,
    //     lastName: this.personalData.lastName
    //   }
    // });
  }

  passportHelp() {
    this.dialog.open(DialogInfoPassportComponent, {
      width: '700px'
    });
  }

  personalNoHelp() {
    this.dialog.open(DialogInfoPersonalNoComponent, {
      width: '700px'
    });
  }

  idCardHelp() {
    this.dialog.open(DialogInfoIdCardComponent, {
      width: '700px'
    });

  }
}

export class CountryList {
  countryName: string;
  capital: string;
  alpha3d: string;
  labelMap: any;
  id: string;
  someLabel: string;
}
