import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {PersonalData} from '../models/personal-data';
import {PersonalDataService} from '../models/services/personal-data.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PersonDataResolver implements Resolve<PersonalData> {
  constructor(private _personDataService: PersonalDataService) {}

  resolve(): Observable<PersonalData> {
    return this._personDataService
      .getUserData()
      .pipe(
        map(res => res.body.data)
      );
  }
}
