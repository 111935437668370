import {Injectable} from '@angular/core';
// noinspection TsLint
import {BehaviorSubject, Observable} from 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/user';
import {PersonalData} from '../models/personal-data';
import {Registration} from '../models/registration';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {BrowserUser} from '../models/browser-user';
import {isStatusOk} from '../functions/response-status/is-status-ok';

@Injectable()
export class UserDataService {
  currentUser: User;

  private _personalData: PersonalData;
  private _registration: Registration;

  private _user$ = new BehaviorSubject<User>(null);

  constructor(private _httpClient: HttpClient,
              private router: Router) {}

  get user$(): Observable<any> {
    return this._user$.asObservable();
  }

  checkUser(email) {
    return this._httpClient
      .post(`${environment.devUrl}/auth/checkUserExists`, {email: email});
  }

  signup(data) {
    return this._httpClient
      .post(`${environment.devUrl}/auth/signup`, data);
  }

  checkGetUser$(): Observable<boolean> {
    const params: any = {
      appId: 'ADMISSIONS__APP'
    };

    return this._httpClient
      .get(`${environment.devUrl}/auth/status`, { params })
      .pipe(
        map((res: any) => {
          if (isStatusOk(res)) {
            this.loginUser(res.body.data);
            return true;
          }

          this.autoLogout();
          return false;
        })
      );
  }

  getStatus(): Observable<boolean> {
    const params: any = {
      appId: 'ADMISSIONS__APP'
    };

    return this._httpClient
      .get(`${environment.devUrl}/auth/status`, {params: params})
      .pipe(
        map((res: any) => {
          if (
            res.status.code === 'STATUS_OK'
          ) {
            this.loginUser(res.body.data);
            return true;
          }

          return false;
        })
      );
  }

  loginUuid(username_id) {
    return this._httpClient.post(`${environment.devUrl}/${'auth/uuid'}`, {username: username_id});
  }

  loginPass(serviceUuid, password) {
    return this._httpClient.post(`${environment.devUrl}/auth/login`, {serviceUuid: serviceUuid, password: password});
  }

  logout(): void {
    this._httpClient
      .delete(`${environment.devUrl}/auth/logout`)
      .subscribe((res: any) => {
        if (res.status.code === 'STATUS_OK') {
          this.autoLogout();
        }
      });
  }

  autoLogout() {
    this.router
      .navigate(['/auth/signin'])
      .then(() => {
        BrowserUser.clearSessionStorage();
        this._user$.next(null);
      });
  }

  loginUser(user) {
    this.currentUser = user;

    BrowserUser.setToken(this.currentUser.authToken);
    BrowserUser.setAnagraficaID(this.currentUser.userAttributes.ADMISSIONS__APP.attributeValue);

    this._user$.next(this.currentUser);
  }

  getCurrentUser() {
    return this.currentUser;
  }

  updatePersonalData(personId: string, personalData) {
    return this._httpClient.put(`${environment.devUrl}/person/${personId}`, personalData);
  }

  requireReset(serviceUuid: string) {
    return this._httpClient.post(`${environment.devUrl}/auth/resetRequire`, {
      serviceUuid: serviceUuid
    });
  }

  resetPassword(resetUuid, changedPassword) {
    return this._httpClient.post(`${environment.devUrl}/auth/resetPass`, {
      serviceUuid: BrowserUser.getUuid(),
      resetUuid: resetUuid,
      changedPassword: changedPassword
    });
  }

  get personalData(): PersonalData {
    return this._personalData;
  }

  set personalData(value: PersonalData) {
    this._personalData = value;
  }

  get registration(): Registration {
    return this._registration;
  }

  set registration(value: Registration) {
    this._registration = value;
  }
}
