export const GraduationYearConstant = (): string[] => {
  const graduationYears: string[] = [];
  let year: number = new Date().getFullYear();

  while (year >= 1990) {
    graduationYears.push(year.toString());
    year--;
  }

  graduationYears.push('>1990');

  return graduationYears;
};
