import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserDataService} from '../../services/user-data.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {DialogWarningComponent} from '../../dialogs/dialog-warning/dialog-warning.component';
import {FormUtility} from '../../utitlities/FormUtilities';
import {TranslateService} from '@ngx-translate/core';
import {SimilarAccountsDialogComponent} from '../../dialogs/similar-accounts-dialog/similar-accounts-dialog.component';
import {User} from '../../models/user';
import {TermsConditionsComponent} from '../../dialogs/terms-conditions/terms-conditions.component';
import {PortalPrivacyComponent} from '../../dialogs/portal-privacy/portal-privacy.component';
import {DialogInfoPersonalNoComponent} from '../../dialogs/dialog-info-personal-no/dialog-info-personal-no.component';
import {DialogSuccessComponent} from '../../dialogs/dialog-success/dialog-success.component';
import {TitleCasePipe, UpperCasePipe} from '@angular/common';
import {BrowserUser} from '../../models/browser-user';

@Component({
  selector: 'app-register-step',
  templateUrl: './register-step.component.html',
  styleUrls: ['./register-step.component.css']
})
export class RegisterStepComponent implements OnInit {
  result: any;
  newUser: User;
  dictionary: any;

  logged = false;

  codice = false;
  personalNoOther = false;
  personalNo = true;


  idType = new FormControl('al', [Validators.required]);

  // noinspection TsLint
  registerForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl(
      '',
      [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]
    ),
    personalNo: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern(new RegExp('[a-zA-Z][0-9]{8}[a-zA-Z]', 'gi'))
    ]),
    codiceNo: new FormControl('', [
      Validators.required,
      Validators.minLength(16),
      Validators.maxLength(16),
      Validators.pattern(new RegExp('[a-zA-Z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z][0-9LMNPQRSTUV]{2}[A-Za-z][0-9LMNPQRSTUV]{3}[a-zA-Z]', 'gi'))
    ]),
    personalNoOther: new FormControl('', [Validators.required]),
    termsAgreement: new FormControl('', [Validators.required]),
    lang: new FormControl(BrowserUser.getLang())
  });

  constructor(
    private _router: Router,
    private _user: UserDataService,
    private dialog: MatDialog,
    private _snack: MatSnackBar,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this._translateService
      .getTranslation(BrowserUser.getLang())
      .subscribe((dictionary) => {
        this.dictionary = dictionary;
      });

    this.registerForm.controls['personalNo'].enable();
    this.registerForm.controls['codiceNo'].disable();
    this.registerForm.controls['personalNoOther'].disable();

    this.idType
      .valueChanges
      .subscribe(value => {
        switch (value) {
          case 'al':
            this.codice = false;
            this.personalNo = true;
            this.personalNoOther = false;
            this.registerForm.controls['personalNo'].enable();
            this.registerForm.controls['codiceNo'].disable();
            this.registerForm.controls['personalNoOther'].disable();
            break;
          case 'it':
            this.codice = true;
            this.personalNo = false;
            this.personalNoOther = false;
            this.registerForm.controls['codiceNo'].enable();
            this.registerForm.controls['personalNo'].disable();
            this.registerForm.controls['personalNoOther'].disable();
            break;
          case 'ot':
            this.codice = false;
            this.personalNo = false;
            this.personalNoOther = true;
            this.registerForm.controls['codiceNo'].disable();
            this.registerForm.controls['personalNo'].disable();
            this.registerForm.controls['personalNoOther'].enable();
            break;
          }
      });
  }

  submit() {
    if (this.registerForm.valid) {
      this.registerForm.controls['personalNo'].enable();

      if (this.idType.value === 'al') {

        this.newUser = new User({
          firstName: new TitleCasePipe().transform(this.registerForm.value.firstName),
          lastName: new TitleCasePipe().transform(this.registerForm.value.lastName),
          email: this.registerForm.value.email,
          personalNo: new UpperCasePipe().transform(this.registerForm.value.personalNo),
        });

      } else if (this.idType.value === 'it') {

        this.newUser = new User({
          firstName: new TitleCasePipe().transform(this.registerForm.value.firstName),
          lastName: new TitleCasePipe().transform(this.registerForm.value.lastName),
          email: this.registerForm.value.email,
          personalNo: new UpperCasePipe().transform(this.registerForm.value.codiceNo),
        });

        this.registerForm.controls['personalNo'].setValue(this.registerForm.value.codiceNo);

      } else if (this.idType.value === 'ot') {

        this.newUser = new User({
          firstName: this.registerForm.value.firstName,
          lastName: this.registerForm.value.lastName,
          email: this.registerForm.value.email,
          personalNo: this.registerForm.value.personalNoOther,
        });

        this.registerForm.controls['personalNo'].setValue(this.registerForm.value.personalNoOther);
      }

      this._user
        .checkUser(this.newUser.email)
        .subscribe((res: any) => {
          if (res.status.code === 'STATUS_OK') {

            this.userExistDialog(this.newUser, res.body.data.fullName);

          } else if (res.status.code === 'NO_CONTENT') {

            this._user
              .signup(this.registerForm.value)
              .subscribe((result: any) => {
                if (result.status.code === 'EXPECTATION_FAILED') {

                  this.similarAccounts(result.body.data, this.registerForm.value, result.status.message);

                } else if (result.status.code === 'STATUS_OK') {

                  this.logged = true;
                  this._user.loginUser(result.body.data);
                  this._router.navigate(['dashboard/profile']).then();
                  this.successModal(result.status.message);

                } else {
                  this._snack.open(
                    result.status.message,
                    null, {
                      duration: 5000,
                      horizontalPosition: 'end',
                      verticalPosition: 'bottom',
                    }
                  );
                }
              });
          }
        });
    } else {

      FormUtility.markAllDirty(this.registerForm);
      this.registerForm.controls['personalNo'].disable();

    }
  }

  userExistDialog(user, name) {
    this.dialog.open(DialogWarningComponent, {
      width: '700px',
      data: {
        title: this.dictionary.global.warning,
        // tslint:disable-next-line
        message: this.dictionary.new_registration_step.warning.message_1 + user.email + this.dictionary.new_registration_step.warning.message_2 + name.toUpperCase(),
        description: this.dictionary.new_registration_step.warning.info
      }
    });
  }

  similarAccounts(list, userData, message) {
    this.dialog.open(SimilarAccountsDialogComponent, {
      width: '600px',
      data: {
        title: this.dictionary.global.warning,
        message: message,
        users: list,
        person: userData
      }
    });
  }

  successModal(message) {
    this.dialog.open(DialogSuccessComponent, {
      width: '600px',
      data: {
        title: this.dictionary.global.success,
        message: message
      }
    });
  }

  termsModal() {
    this.dialog.open(TermsConditionsComponent, {
      width: '784px'
    });
  }

  privacyModal() {
    this.dialog.open(PortalPrivacyComponent, {
      width: '800px'
    });
  }

  personalNoHelp() {
    this.dialog.open(DialogInfoPersonalNoComponent, {
      width: '700px'
    });
  }
}
