import {Component, OnInit} from '@angular/core';
import {FileUploadComponent} from '../../../dialogs/file-upload/file-upload.component';
import {MatDialog, MatSnackBar} from '@angular/material';
import {UserDataService} from '../../../services/user-data.service';
import {StepsDataService} from '../../../services/steps-data.service';
import {Router} from '@angular/router';
import {Registration} from '../../../models/registration';
import {UploadDocument} from '../../../models/upload-document';
import {AdmissionService} from '../../../services/admission.service';
import {DialogDeleteComponent} from '../../../dialogs/dialog-delete/dialog-delete.component';
import {Observable} from 'rxjs/Observable';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../models/user';
import {DialogWarningComponent} from '../../../dialogs/dialog-warning/dialog-warning.component';
import {BrowserUser} from '../../../models/browser-user';

@Component({
  selector: 'app-admission-documents',
  templateUrl: './admission-documents.component.html',
  styleUrls: ['./admission-documents.component.css']
})
export class AdmissionDocumentsComponent implements OnInit {
  currentUser: User;
  allDocuments: UploadDocument[] = [];
  documents: DocumentList[] = [];
  registration: Registration;
  uploadingLoaders: boolean[];
  dictionary: any;
  docReady = false;
  sUsrAg = navigator.userAgent;
  sBrowser: string;
  isMozilla: boolean;
  isChrome: boolean;

  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private route: Router,
    private _userData: UserDataService,
    private _admissionsService: AdmissionService,
    private _translateService: TranslateService,
    private _steps: StepsDataService
  ) {}

  ngOnInit() {
    this.currentUser = this._userData.getCurrentUser();
    this.registration = this._steps.registration;

    this.getDocuments(this.registration.id);

    this._translateService
      .getTranslation(BrowserUser.getLang())
      .subscribe((dictionary) => this.dictionary = dictionary);

    this._translateService
      .onLangChange
      .subscribe(translate => this.dictionary = translate.translations);

    this.checkUserAgent();
  }

  getDocuments(registrationId: string) {
    this._admissionsService
      .getDocumentsList(registrationId)
      .subscribe( (result: any) => {
        if (result.status.code === 'STATUS_OK') {
          this.documents = result.body.data.list;
          this.uploadingLoaders = Array(this.documents.length).fill(false);
          this.docReady = true;
        }
      });
  }

  checkUserAgent() {
    if (this.sUsrAg.indexOf('Firefox') > -1) {
      this.sBrowser = 'Mozilla Firefox';
      this.isMozilla = true;
    } else if (this.sUsrAg.indexOf('Opera') > -1) {
      this.sBrowser = 'Opera';
    } else if (this.sUsrAg.indexOf('Trident') > -1) {
      this.sBrowser = 'Microsoft Internet Explorer';
    } else if (this.sUsrAg.indexOf('Edge') > -1) {
      this.sBrowser = 'Microsoft Edge';
    } else if (this.sUsrAg.indexOf('Chrome') > -1) {
      this.isChrome = true;
    } else if (this.sUsrAg.indexOf('Safari') > -1) {
      this.sBrowser = 'Apple Safari';
    } else {
      this.sBrowser = 'unknown';
    }
  }

  cancel(index, document, uploadedFile) {
    let fileName;

    if (document.originalFilename) {
      fileName = document.originalFilename;
    } else {
      fileName = uploadedFile;
    }

    const dialogRef = this.dialog
      .open(DialogDeleteComponent, {
        width: '800px',
        data: {
          title: this.dictionary.global.are_you_sure_to_delete,
          description: fileName
        }
      });

    dialogRef
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this._admissionsService
            .deleteDocument(this.registration.id, document.id)
            .subscribe( (result: any) => {
              if (result.status.code === 'STATUS_OK') {
                this.documents[index].file = null;
                this.documents[index].upload = false;
                this.getDocuments(this.registration.id);
                this.snack.open(
                  this.dictionary.admission_documents_step.doc_deleted,
                  '', {
                    duration: 5000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                  }
                );
              } else {
                this.snack.open(
                  result.status.message,
                  result.status.code, {
                    duration: 5000,
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                  }
                );
              }
            });
        }
      });
  }

  download(document) {
    this._admissionsService.downloadDocument(
      this.registration.id,
      document.id,
    );
  }

  handleFileInput(event, index, document) {
    const target = event.target || event.srcElement;
    const files = target.files;
    this.uploadingLoaders[index] = true;

    if (files && files.item(0).size < 20000000) {
      if (document) {
        this._admissionsService
          .uploadDocument(this.registration.id, document.id, files.item(0))
          .catch( () => {
            this.uploadingLoaders[index] = false;
            return Observable.throwError('Server Error');
          })
          .subscribe( (result: any) => {
            if (result.status.code === 'STATUS_OK') {
              this.uploadingLoaders[index] = false;
              this.documents[index].file = files.item(0);
              this.allDocuments.push(new UploadDocument(files.item(0).name, files.item(0)));

              this.snack
                .open(this.dictionary.admission_documents_step.doc_uploaded, '', {
                  duration: 5000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                });

              this.getDocuments(this.registration.id);
            } else {

              this.uploadingLoaders[index] = false;
              this.snack
                .open(result.status.message, '', {
                  duration: 5000,
                  horizontalPosition: 'end',
                  verticalPosition: 'bottom',
                });
            }
          });
      }
    } else {
      this.uploadingLoaders[index] = false;
      this.snack
        .open(this.dictionary.admission_documents_step.size_to_large, '', {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
        });
    }
  }

  viewDocumentNote(doc: DocumentList): void {
    this.dialog
      .open(DialogWarningComponent, {
        width: '700px',
        data: {
          title: this.dictionary.global.warning,
          description: doc.documentNote
        }
      });
  }

  getInfo(doc) {
    this.dialog.open(FileUploadComponent, {
      width: '800px',
      data: {
        title: this.dictionary.admission_documents_step.doc_info,
        description: doc.description || doc.docName
      }
    });
  }

  approveUploads() {
    return this.documents.some((doc) => {
      return doc.attachmentRequired && !(doc.file || doc.originalFileName);
    });
  }

  continue() {
    this.route.navigate(['dashboard/prepayment', this.registration.prepaymentInvoiceId, this.registration.id]);
  }

  continueLater() {
    this.route.navigate(['dashboard/profile']);
  }

  examExpiry() {
    const timeNow = Date.now();

    if (this.registration && this.registration.documentsEnd) {
      const parseDate = Date.parse(this.registration.documentsEnd);
      let difference: number;

      difference = (parseDate - timeNow ) / 1000 / 60;
      if (difference < 0) {
        return false;
      }
    }

    return true;
  }
}

export class DocumentList {
  examId: string;
  docName: string;
  bytesId: string;
  docRequired: boolean;
  attachmentRequired: boolean;
  docFlagDirty: boolean;
  registrationId: string;
  documentNote: string;
  description: string;
  documentId: string;
  id: string;
  someLabel: string;
  upload: boolean;
  originalFileName: string;
  fileName: string;
  file: File;
  delivered: boolean;
}
