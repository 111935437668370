import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Notification} from '../../models/notification';
import {NotificationService} from '../../models/services/notification.service';
import {BrowserUser} from '../../models/browser-user';

@Component({
  selector: 'app-dialog-notifications',
  templateUrl: './dialog-notifications.component.html',
  styleUrls: ['./dialog-notifications.component.css']
})
export class DialogNotificationsComponent implements OnInit {

  notifications: Notification[] = [];
  noData = false;

  constructor(public dialogRef: MatDialogRef<DialogNotificationsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _notification: NotificationService) {
  }

  ngOnInit() {
    this._notification.getNotification().subscribe((res: any) => {
      this.notifications = res.body.data.list;
      if (res.body.data.totalRecords === 0) {
        this.noData = true;
      }
    });
  }

  readNotification(notification, index) {
    if (!(notification.rcvStatus === 'EMAIL_STAT_READ')) {
      if (this._notification.setNotificationRead(BrowserUser.getAnagraficaId(), notification.id).subscribe()) {
        this.notifications[index].rcvStatus = 'EMAIL_STAT_READ';
      }
    }
  }

  downloadAttachment(notificationId, attachmentId) {
    this._notification.downloadAttachment(notificationId, attachmentId);
  }

  close() {
    this.dialogRef.close();
  }
}
