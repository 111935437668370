import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../models/services/notification.service';
import {UserDataService} from '../services/user-data.service';
import {MatDialog} from '@angular/material';
import {Router} from '@angular/router';
import {PersonalData} from '../models/personal-data';
import {Notification} from '../models/notification';
import {User} from '../models/user';
import {DialogNotificationsComponent} from '../dialogs/dialog-notifications/dialog-notifications.component';
import {ProfileDataDialogComponent} from '../dialogs/profile-data-dialog/profile-data-dialog.component';
import {StepsDataService} from '../services/steps-data.service';
import {ProfilePhotoService} from '../services/profile-photo.service';
import {Steps as StepEnum} from '../steps/enum-steps';
import {BrowserUser} from '../models/browser-user';
import {LangType} from '../models/lang.type';
import {StepModel} from '../models/step.model';
import {LangModel} from '../models/lang.model';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  @Input()
  set steps(value: StepModel[]) {
    if (value && value.length) {
      this._steps = [...value];
    }
  }


  get steps(): StepModel[] {
    return this._steps;
  }

  private _steps: StepModel[];

  @Input()
  set personalData(value: PersonalData) {
    if (value) {
      this._personalData = value;
    }
  }

  private _personalData: PersonalData;

  languages: LangModel[] = [
    {
      lang: 'English',
      prefix: 'en'
    },

    {
      lang: 'Italian',
      prefix: 'it'
    },

    {
      lang: 'Shqip',
      prefix: 'sq'
    }
  ];

  selectedLang: LangModel;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _userService: UserDataService,
    private _stepsService: StepsDataService,
    private _notifications: NotificationService,
    private _profilePhoto: ProfilePhotoService,
    private _translateService: TranslateService
  ) {

    this.selectedLang = this.languages
      .find(item => item.prefix === BrowserUser.getLang())
      || {
        lang: 'Italiano',
        prefix: 'it'
      };

    this._steps = [];
  }

  currentUser: User;
  notificationNumber = 0;
  notifications: Notification[] = [];
  notificationBadge: boolean;

  ngOnInit() {
    this.notificationBadge = true;

    this._userService
      .user$
      .subscribe((user: User) => {
        if (user) {
          this.currentUser = user;

          if (this.currentUser) {
            this.getNotifications();
          }
        }
      });
  }

  get fullName(): string| undefined {
    if (this._personalData) {
      return `${this._personalData.firstName} ${this._personalData.lastName}`;
    }

    return undefined;
  }

  get pictureSrc(): string {
    return `anag/${BrowserUser.getAnagraficaId()}.jpg`;
  }

  personalDataCompleted(): boolean {
    if (this._personalData) {
      return this._personalData.personComplete;
    }

    return false;
  }

  getNotifications() {
    this._notifications
      .getNotification()
      .subscribe((res: any) => {
        this.notifications = res.body.data.list;
        this.notificationNumber = 0;
        for (const notification of this.notifications) {
          if (!(notification.rcvStatus === 'EMAIL_STAT_READ')) {
            this.notificationNumber ++;
          }
        }
      });
  }

  showNotifications() {
    this.notificationBadge = false;

    this.dialog
      .open(DialogNotificationsComponent, {
        width: '450px',
        position: {
          top: '111px',
          right: '32px'
        },
        data: {
          personId: this.currentUser.userAttributes.ADMISSIONS__APP.attributeValue
        }
      })
      .afterClosed()
      .subscribe(() => {
        this.getNotifications();
        this.notificationBadge = true;
      });
  }

  selectLanguage(language) {
    this.selectedLang = language;
    BrowserUser.setLang(this.selectedLang.prefix as LangType);
    this._translateService.use(BrowserUser.getLang());
  }

  showPersonalData() {
    this.dialog
      .open(ProfileDataDialogComponent, {
        width: '1000px',
        data: { title: 'Edit Personal Data'}
      });
  }

  navigateToStep(step: StepEnum): void {
    this._stepsService.navigateToStep(step);
  }

  logOut() {
    this._userService.logout();
  }
}
