import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserDataService} from '../../../services/user-data.service';
import {StepsDataService} from '../../../services/steps-data.service';
import {Steps} from '../../enum-steps';
import {AdmissionService} from '../../../services/admission.service';
import {UniversityList} from '../../../models/univesritiesList';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog, MatSnackBar} from '@angular/material';
import {DialogWarningComponent} from '../../../dialogs/dialog-warning/dialog-warning.component';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Registration} from '../../../models/registration';
import {User} from '../../../models/user';
import {BrowserUser} from '../../../models/browser-user';

@Component({
  selector: 'app-admission-courses',
  templateUrl: './admission-courses.component.html',
  styleUrls: ['./admission-courses.component.css']
})
export class AdmissionCoursesComponent implements OnInit {
  buttonIndex = 0;
  faculties: List[];
  courses: AdmissionExam[];
  university: UniversityList;
  selectedCourse: AdmissionExam;
  currentRegistration: Registration;
  dictionary: any;
  currentUser: User;
  userId: string;
  registrationType: string;

  formCtrl = new FormGroup({
    ctrl: new FormControl()
  });

  constructor(private _router: Router,
              private snack: MatSnackBar,
              private _steps: StepsDataService,
              private _userData: UserDataService,
              private dialog: MatDialog,
              private _activatedRoute: ActivatedRoute,
              private _admissionService: AdmissionService,
              private _translateService: TranslateService) {}

  ngOnInit() {
    this.currentRegistration = this._userData.registration;

    this._userData
      .user$
      .subscribe((user) => {
        this.currentUser = user;
        this.userId = this.currentUser.userAttributes.ADMISSIONS__APP.attributeValue;

        this._activatedRoute
          .params
          .subscribe(routeParams => {
            this.registrationType = routeParams.registrationType;
            this.getPossibleExams(this.userId, routeParams.registrationType);
          });
      });

    this._translateService
      .onLangChange
      .subscribe((translate: LangChangeEvent) => this.dictionary = translate.translations);

    this._translateService
      .getTranslation(BrowserUser.getLang())
      .subscribe((dictionary) => {
        this.dictionary = dictionary;
        this._steps.markStep(Steps.stepThree);
      });
  }

  getPossibleExams(userId, registrationType) {
    this._admissionService.getPossibleExams(userId, registrationType)
      .subscribe((result: any) => {
      if (result.body.data.list) {
        this.faculties = result.body.data.list;
        if (this.faculties.length > 0) {
          this.courses = this.faculties[0].admissionExams;
        }
      }
    });
  }

  getCourses(index) {
    this.courses = this.faculties[index].admissionExams;
    this.buttonIndex = index;
  }

  disableButton() {
    return !this.selectedCourse;
  }

  refreshChoice() {
    this.selectedCourse = this.formCtrl.value.ctrl;
  }

  continue() {
    this.selectedCourse = this.formCtrl.value.ctrl;
    this._admissionService
      .registerExam(this.userId, this.selectedCourse.id, this.registrationType)
      .subscribe((result: any) => {
        if (result.status.code === 'STATUS_OK') {

          this._userData.registration.id = result.body.data.id;
          this._steps.registration = this._userData.registration;
          this._router.navigate(['dashboard/admission-documents', this._userData.registration.id]);

        } else {

          this.dialog.open(DialogWarningComponent, {
            width: '700px',
            data: {
              title: this.dictionary.global.warning,
              description: result.status.message
            }
          });
        }
      });
  }

  goBack() {
    this._router.navigate(['dashboard/profile']);
  }
}

export class CourseWrapper {
  private _id: string;
  private _facultyId: string;
  private _course: string;
  private _degree: string;
  private _description: string;
  private _checked: boolean;

  get checked(): boolean {
    return this._checked;
  }

  set checked(value: boolean) {
    this._checked = value;
  }

  get degree(): string {
    return this._degree;
  }

  set degree(value: string) {
    this._degree = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get facultyId(): string {
    return this._facultyId;
  }

  set facultyId(value: string) {
    this._facultyId = value;
  }

  get course(): string {
    return this._course;
  }

  set course(value: string) {
    this._course = value;
  }
}

export class AdmissionExam {
  name: string;
  courseId: string;
  targetStudents: string;
  targetCourseEdition: string;
  registrationStart: Date;
  registrationClose: Date;
  registrationQuota: number;
  maxWinners: number;
  totalPoints: number;
  examDate: Date;
  enabled: boolean;
  status: string;
  gradCourseName: string;
  facultyId: string;
  facultyName: string;
  id: string;
  someLabel: string;
  checked: boolean;
  test: string;
}

export class List {
  id: string;
  name: string;
  admissionExams: AdmissionExam[];
}
