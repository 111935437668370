import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
  selector: 'app-summary-dialog',
  templateUrl: './summary-dialog.component.html',
  styleUrls: ['./summary-dialog.component.css']
})
export class SummaryDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SummaryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {title: string, registrationId: string}) {
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
