import { Component, OnInit } from '@angular/core';
import {StepsDataService} from '../services/steps-data.service';
import {TranslateService} from '@ngx-translate/core';
import {UserDataService} from '../services/user-data.service';
import {BrowserUser} from '../models/browser-user';
import {LangType} from '../models/lang.type';

@Component({
  selector: 'app-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
  styleUrls: ['./auth-wrapper.component.css']
})
export class AuthWrapperComponent implements OnInit {

  loggedIn = false;
  title = 'Portal Wizard UNIZKM';

  languages = [
    {
      lang: 'English',
      prefix: 'en'
    },

    {
      lang: 'Italian',
      prefix: 'it'
    },

    {
      lang: 'Shqip',
      prefix: 'sq'
    }
  ];
  selectedLang = {
    lang: 'English',
    prefix: 'en'
  };

  constructor(private _translateService: TranslateService,
              private _userData: UserDataService) {
    if (!BrowserUser.getLang()) {
      if (
        this._translateService.getBrowserLang() === 'it' ||
        this._translateService.getBrowserLang() === 'en' ||
        this._translateService.getBrowserLang() === 'sq'
      ) {
        this._translateService.setDefaultLang(this._translateService.getBrowserLang());
        this._translateService.use(this._translateService.getBrowserLang());
        BrowserUser.setLang(this._translateService.getBrowserLang() as LangType);
      } else {
        this._translateService.setDefaultLang('it');
        this._translateService.use('it');
        BrowserUser.setLang('it');
      }

      this.selectedLang = this.languages.find((language) => language.prefix === BrowserUser.getLang() as string);
    } else {
      this._translateService.setDefaultLang(BrowserUser.getLang());
      this._translateService.use(BrowserUser.getLang());

      this.selectedLang = this.languages.find((language) => language.prefix === BrowserUser.getLang() as string);
    }
  }

  ngOnInit() {
    this._userData.user$.subscribe((user) => {
      this.loggedIn = user && user.id !== undefined && user.authToken;
    });
  }

  selectLanguage(language) {
    this.selectedLang = language;
    BrowserUser.setLang(this.selectedLang.prefix as LangType);
    this._translateService.use(BrowserUser.getLang());
  }

}
