
import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute} from '@angular/router';
import { UserDataService } from '../../../services/user-data.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class PreferencesGuard implements CanActivate {
  constructor(
    private _route: Router,
    private _userService: UserDataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this._userService.personalData && route.params['registrationType']) {
      if (this._userService.personalData.countryOfHighSchool !== 'ALB') {
        this._route.navigate(['dashboard/admission-courses', route.params['registrationType']]);
      } else {
        if (this._userService.getCurrentUser() && this._userService.personalData && this._userService.registration) {
          return true;
        } else {
          this._route.navigate(['./dashboard/registration-type']);
          return false;
        }
      }
    } else {
      this._route.navigate(['./dashboard/registration-type']);
      return false;
    }
  }
}
