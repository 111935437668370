import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserDataService} from '../../../services/user-data.service';
import {StepsDataService} from '../../../services/steps-data.service';
import {PersonalData} from '../../../models/personal-data';
import {Registration} from '../../../models/registration';
import {TranslateService} from '@ngx-translate/core';
import {RegistrationService} from '../../../models/services/registration.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  @Input()
  set registrationId(value: string) {
    this._registrationService
      .getRegistration(value)
      .subscribe(registration => {
        if (registration) {
          this.currentRegistration = registration;
        }
      });
  }

  @Input()
  context = 'view';

  personalData: PersonalData;
  currentRegistration: Registration;

  constructor (
    private _router: Router,
    private _userDataService: UserDataService,
    private _stepsDataService: StepsDataService,
    private _registrationService: RegistrationService,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.personalData = this._userDataService.personalData;

    if (this.context === 'view') {
      this.currentRegistration = this._userDataService.registration;
    }
  }

  canShowSummary(): boolean {
    return !!this.currentRegistration && !!this.personalData;
  }

  get fullName(): string {
    const {firstName, lastName} = this.currentRegistration;
    return `${firstName} ${lastName}`;
  }

  get personalDataCompleted(): string {
    const {personalDataCompleted} = this.currentRegistration;
    return this.yesOrNo(personalDataCompleted);
  }

  get allRequiredDocumentsUploaded(): string {
    const {personalDataCompleted, allRequiredDocumentsUploaded} = this.currentRegistration;
    return this.previousCompleted(personalDataCompleted, this.yesOrNo.bind(this), [allRequiredDocumentsUploaded]);
  }

  get prepaymentCompleted(): string {
    const {allRequiredDocumentsUploaded, prepaymentCompleted} = this.currentRegistration;
    return this.previousCompleted(allRequiredDocumentsUploaded, this.yesOrNo.bind(this), [prepaymentCompleted]);
  }

  get allRequiredDocumentsAccepted(): string {
    const {documentsCheckCompleted, allRequiredDocumentsAccepted} = this.currentRegistration;
    return this.previousCompleted(documentsCheckCompleted, this.yesOrNo.bind(this), [allRequiredDocumentsAccepted]);
  }

  get avgGradeCheckValid(): string {
    const {avgGradeCheckCompleted, avgGradeCheckValid} = this.currentRegistration;
    return this.previousCompleted(avgGradeCheckCompleted, this.yesOrNo.bind(this), [avgGradeCheckValid]);
  }

  get paymentCompleted(): string {
    const {avgGradeCheckValid, paymentCompleted} = this.currentRegistration;
    return this.previousCompleted(avgGradeCheckValid, this.yesOrNo.bind(this), [paymentCompleted]);
  }

  private yesOrNo(statement: boolean): string {
    return this._translateService.instant(
      statement ? 'global.yes' : 'global.no'
    );
  }

  // noinspection JSMethodCanBeStatic
  private previousCompleted(previousStatement: boolean, handler: (...args) => string, args: any[] = []): string {
    return previousStatement ? handler(...args) : '-';
  }

  canShowSummaryAction(): boolean {
    return this.context === 'view';
  }

  closeSubmission() {
    this._router.navigateByUrl('dashboard/profile');
  }

  canProceed(): boolean {
    const {allRequiredDocumentsAccepted, avgGradeCheckValid} = this.currentRegistration;
    return allRequiredDocumentsAccepted && avgGradeCheckValid;
  }

  next() {
    this._router.navigate(
      ['/dashboard', 'payment', this.currentRegistration.paymentInvoiceId, this.currentRegistration.id]
    );
  }
}
