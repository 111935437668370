import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router} from '@angular/router';
import {UserDataService} from '../../services/user-data.service';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ProfileCanActivateGuard implements CanActivate {
  constructor(private _route: Router,
              private _userService: UserDataService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._userService.checkGetUser$()
      .do((userExists) => {
        if (!userExists) {
          // this._route.navigate(['./auth/signin']);
        }
      }).map((res: any) => {
        return res.body.data !== undefined;
      });
  }
}
