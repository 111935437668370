export enum Steps {
  stepOne = 0,
  stepTwo = 1,
  stepThree = 2,
  stepFour = 3,
  stepFive = 4,
  stepSix = 5,
  stepSeven = 6,
  stepEight = 7
}
