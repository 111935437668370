import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UserDataService} from '../../services/user-data.service';
import {FormControl, Validators} from '@angular/forms';
import {Preference} from '../../models/preference';
import {FacultyService} from '../../models/services/faculty.service';
import {DegreeList, FacultyList, UniversityList} from '../../models/univesritiesList';
import {AdmissionService} from '../../services/admission.service';

@Component({
  selector: 'app-preferences-dialog',
  templateUrl: './preferences-dialog.component.html',
  styleUrls: ['./preferences-dialog.component.css']
})
export class PreferencesDialogComponent implements OnInit {
  selectedPreference: Preference;
  universityControl = new FormControl(undefined, [Validators.required]);
  facultyControl = new FormControl({value: undefined, disabled: !this.universityControl.value}, [Validators.required]);
  coursesControl = new FormControl({
    value: undefined,
    disabled: !this.universityControl.value && !this.facultyControl.value
  }, [Validators.required]);

  universities: UniversityList[] = [];
  faculties: FacultyList[];
  courses: DegreeList[];

  constructor(public dialogRef: MatDialogRef<PreferencesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _admissionService: AdmissionService) {
  }

  ngOnInit() {
    this._admissionService.getUniversitiesTree().subscribe((list: any) => {
      this.universities = list.body.data.list;
    });
  }

  fillFaculties() {
    this.faculties = this.universityControl.value.facultyList;
    if (this.faculties) {
      this.facultyControl.enable();
    }
  }

  fillCourses() {
    this.courses = this.facultyControl.value.degreeList;
    if (this.courses) {
      this.coursesControl.enable();
    }
  }

  close() {
    this.dialogRef.close();
  }

  addToPreferences() {
    this._admissionService.addQSHAPreference(this.data.userId,
      this.coursesControl.value.id).subscribe((res: any) => {
      if (res.status.code === 'STATUS_OK') {
        this.close();
      }
    });
    // if (this.universityControl.valid && this.facultyControl.valid && this.coursesControl.valid) {
    //   this.selectedPreference = new Preference({
    //     preferenceId: 'PREF000001',
    //     universityId: this.universityControl.value.id,
    //     facultyId: this.facultyControl.value.id,
    //     courseId: this.coursesControl.value.id,
    //     course: this.coursesControl.value.course,
    //     degree: this.coursesControl.value.degree
    //   });
    //
    //   this.dialogRef.close(this.selectedPreference);
    // } else {
    //   this.universityControl.markAsDirty();
    //   this.universityControl.markAsTouched();
    //   this.facultyControl.markAsDirty();
    //   this.facultyControl.markAsTouched();
    //   this.coursesControl.markAsDirty();
    //   this.coursesControl.markAsTouched();
    // }
  }
}
