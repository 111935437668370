
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { UserDataService } from '../../../services/user-data.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AdmissionCoursesGuard implements CanActivate {
  constructor(
    private _route: Router,
    private _userService: UserDataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._userService.getCurrentUser() && this._userService.personalData) {
      return true;
    }
    this._route.navigate(['./dashboard/registration-type']);
    return false;
  }
}
