import {Routes} from '@angular/router';
import {RegisterStepComponent} from './auth/register-step/register-step.component';
import {LoginStepComponent} from './auth/login-step/login-step.component';
import {ForgotPasswordComponent} from './auth/forgot-password-step/forgot-password/forgot-password.component';
import {ProfilePageComponent} from './steps/step-one/profile-page/profile-page.component';
import {PersonalDataComponent} from './steps/step-two/personal-data/personal-data.component';
import {RegistrationTypeComponent} from './steps/step-three/registration-type/registration-type.component';
import {PreferencesComponent} from './steps/step-two/preferences/preferences.component';
import {PreferencesGuard} from './steps/step-two/guards/preferences-guard';
import {AdmissionCoursesComponent} from './steps/step-three/admission-courses/admission-courses.component';
import {AdmissionDocumentsComponent} from './steps/step-four/admission-documents/admission-documents.component';
import {ConfirmationComponent} from './steps/step-six/confirmation/confirmation.component';
import {PaymentComponent} from './steps/step-seven/payment/payment.component';
import {ResultComponent} from './steps/step-eight/result/result.component';
import {AdmissionCoursesGuard} from './steps/step-three/guards/admission-courses-guard';
import {PasswordStepComponent} from './auth/password-step/password-step.component';
import {AuthGuard} from './auth/guards/auth.guard';
import {AuthUserComponent} from './auth/forgot-password-step/auth-user/auth-user.component';
import {AuthWrapperComponent} from './auth/auth-wrapper.component';
import {StepsComponent} from './steps/steps.component';
import {PrepaymentComponent} from './steps/step-five/prepayment/prepayment.component';
import {PersonalDataGuard} from './steps/step-two/guards/personal-data-guard';
import {PersonDataResolver} from './resolvers/person-data.resolver';
import {ParentPersonDataResolver} from './resolvers/parent-person-data.resolver';
import {Steps} from './steps/enum-steps';
import {ActivateRegistrationGuard} from './guards/activate-registration.guard';
import {ActivateInvoiceGuard} from './guards/activate-invoice.guard';
import {Registration} from './models/registration';

const personalData = (registration: Registration): boolean => registration.personalDataCompleted;
const documentUploaded = (registration: Registration): boolean => registration.allRequiredDocumentsUploaded;
const prepayment = (registration: Registration): boolean => registration.prepaymentCompleted;
const documentAccepted = (registration: Registration): boolean =>
  registration.allRequiredDocumentsAccepted && registration.avgGradeCheckValid;
const payment = (registration: Registration): boolean => registration.paymentCompleted;

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/profile',
    pathMatch: 'full'
  },

  {
    path: 'request',
    component: AuthWrapperComponent,
    children: [
      {
        path: 'forgot-password/:id',
        component: AuthUserComponent
      },
      {
        path: 'reset-password/:id',
        component: ForgotPasswordComponent
      },
      {
        path: '',
        redirectTo: '/auth/signin',
        pathMatch: 'full'
      },
    ]
  },

  {
    path: 'auth',
    component: AuthWrapperComponent,
    children: [
      {
        path: 'register',
        component: RegisterStepComponent
      },
      {
        path: 'signin',
        component: LoginStepComponent
      },
      {
        path: 'login/:uuid',
        component: PasswordStepComponent
      },
      {
        path: 'forgot-password/:id',
        component: AuthUserComponent
      },
      {
        path: 'reset-password/:id',
        component: ForgotPasswordComponent
      },
      {
        path: '',
        redirectTo: '/auth/signin',
        pathMatch: 'full'
      },
    ]
  },

  {
    path: 'dashboard',
    component: StepsComponent,
    canActivate: [AuthGuard],
    resolve: {
      personData: PersonDataResolver,
    },
    children: [
      {
        path: 'profile',
        component: ProfilePageComponent,
        data: {
          step: Steps.stepOne
        },
        resolve: {
          parentPersonData: ParentPersonDataResolver
        }
      },
      {
        path: 'anagrafica',
        component: PersonalDataComponent,
        canActivate: [PersonalDataGuard]
      },
      {
        path: 'registration-type',
        component: RegistrationTypeComponent
      },
      {
        path: 'preferences/:registrationType',
        component: PreferencesComponent,
        canActivate: [PreferencesGuard]
      },
      {
        path: 'admission-courses/:registrationType',
        component: AdmissionCoursesComponent,
        canActivate: [AdmissionCoursesGuard]
      },
      {
        path: 'admission-documents/:id',
        component: AdmissionDocumentsComponent,
        data: {
          step: Steps.stepFour,
          stepComplete: personalData
        },
        resolve: {
          parentPersonData: ParentPersonDataResolver
        },
        canActivate: [ActivateRegistrationGuard]
      },
      {
        path: 'prepayment/:invoiceId/:id',
        component: PrepaymentComponent,
        data: {
          step: Steps.stepFive,
          stepComplete: documentUploaded
        },
        resolve: {
          parentPersonData: ParentPersonDataResolver
        },
        canActivate: [ActivateInvoiceGuard]
      },
      {
        path: 'confirmation/:id',
        component: ConfirmationComponent,
        data: {
          step: Steps.stepSix,
          stepComplete: prepayment
        },
        resolve: {
          parentPersonData: ParentPersonDataResolver
        },
        canActivate: [ActivateRegistrationGuard]
      },
      {
        path: 'payment/:invoiceId/:id',
        component: PaymentComponent,
        data: {
          step: Steps.stepSeven,
          stepComplete: documentAccepted
        },
        resolve: {
          parentPersonData: ParentPersonDataResolver
        },
        canActivate: [ActivateInvoiceGuard]
      },
      {
        path: 'result/:id',
        component: ResultComponent,
        data: {
          step: Steps.stepEight,
          stepComplete: payment
        },
        resolve: {
          parentPersonData: ParentPersonDataResolver
        },
        canActivate: [ActivateRegistrationGuard]
      }
    ]
  },

  {
    path: '**',
    redirectTo: '/dashboard/profile',
    pathMatch: 'full'
  }
];
