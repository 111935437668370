import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Steps as StepEnum} from '../steps/enum-steps';
import {Router} from '@angular/router';
import {StepGuardEnum} from '../steps/guards/step-guard.enum';
import {Registration} from '../models/registration';
import {StepModel} from '../models/step.model';
import {TranslateService} from '@ngx-translate/core';
import {RegistrationService} from '../models/services/registration.service';
import {BrowserUser} from '../models/browser-user';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class StepsDataService {
  private readonly _onDestroy: Subject<any>;
  private _steps$: BehaviorSubject<StepModel[]>;

  steps: StepModel[];

  private _registration: Registration;

  constructor(private _translateService: TranslateService,
              private _registrationService: RegistrationService,
              private _router: Router) {

    this._onDestroy = new Subject();
    this._steps$ = new BehaviorSubject<StepModel[]>([]);

    this._translateService
      .getTranslation(BrowserUser.getLang())
      .subscribe(dictionary => this._initSteps(dictionary));
  }

  get registration(): Registration {
    if (this._registration) {
      return this._registration;
    }

    return null;
  }

  set registration(value: Registration) {
    if (value) {
      this._registration = value;
    }
  }

  private _initSteps(dictionary: any): void {
    if (this.steps) {
      this.steps = [
        new StepModel({
          ...this.steps[StepEnum.stepOne],
          title: dictionary.step.profile.label,
          description: dictionary.step.profile.description,
        }),

        new StepModel({
          ...this.steps[StepEnum.stepTwo],
          title: dictionary.step.anagrafica.label,
          description: dictionary.step.anagrafica.description
        }),

        new StepModel({
            ...this.steps[StepEnum.stepThree],
          title: dictionary.step.corsi_di_ammissione.label,
          description: dictionary.step.corsi_di_ammissione.description
        }),

        new StepModel({
          ...this.steps[StepEnum.stepFour],
          title: dictionary.step.admission_documents.label,
          description: dictionary.step.admission_documents.description
        }),

        new StepModel({
          ...this.steps[StepEnum.stepFive],
          title: dictionary.step.prepayment_documents.label,
          description: dictionary.step.prepayment_documents.description
        }),

        new StepModel({
          ...this.steps[StepEnum.stepSix],
          title: dictionary.step.confirmation.label,
          description: dictionary.step.confirmation.description
        }),

        new StepModel({
          ...this.steps[StepEnum.stepSeven],
          title: dictionary.step.payment.label,
          description: dictionary.step.payment.description
        }),

        new StepModel({
          ...this.steps[StepEnum.stepEight],
          title: dictionary.step.result.label,
          description: dictionary.step.result.description,
        })
      ];
    } else {
      this.steps = [
        new StepModel({
          step: StepEnum.stepOne,
          stepGuard: StepGuardEnum.personalData,
          title: dictionary.step.profile.label,
          description: dictionary.step.profile.description,
          stepType: 'active',
          route: ['profile']
        }),

        new StepModel({
          step: StepEnum.stepTwo,
          stepGuard: StepGuardEnum.personalData,
          title: dictionary.step.anagrafica.label,
          description: dictionary.step.anagrafica.description,
          route: ['anagrafica']
        }),

        new StepModel({
          step: StepEnum.stepThree,
          stepGuard: StepGuardEnum.personalData,
          title: dictionary.step.corsi_di_ammissione.label,
          description: dictionary.step.corsi_di_ammissione.description
        }),

        new StepModel({
          step: StepEnum.stepFour,
          stepGuard: StepGuardEnum.documentsPending,
          title: dictionary.step.admission_documents.label,
          description: dictionary.step.admission_documents.description,
          route: ['admission-documents']
        }),

        new StepModel({
          step: StepEnum.stepFive,
          stepGuard: StepGuardEnum.prepaymentPending,
          title: dictionary.step.prepayment_documents.label,
          description: dictionary.step.prepayment_documents.description,
          route: ['prepayment']
        }),

        new StepModel({
          step: StepEnum.stepSix,
          stepGuard: StepGuardEnum.confirmationPending,
          title: dictionary.step.confirmation.label,
          description: dictionary.step.confirmation.description,
          route: ['confirmation']
        }),

        new StepModel({
          step: StepEnum.stepSeven,
          stepGuard: StepGuardEnum.paymentPending,
          title: dictionary.step.payment.label,
          description: dictionary.step.payment.description,
          route: ['payment']
        }),

        new StepModel({
          step: StepEnum.stepEight,
          stepGuard: StepGuardEnum.resultPending,
          title: dictionary.step.result.label,
          description: dictionary.step.result.description,
          route: ['result']
        })
      ];
    }
  }

  /**
   * DO NOT USE IN RESOLVER
   */
  getSteps(): Observable<Array<StepModel>> {
    return this._steps$
      .asObservable()
      .pipe(
        takeUntil(this._onDestroy),
        switchMap(() => this._translateService.getTranslation(BrowserUser.getLang())),
        tap(dictionary => this._initSteps(dictionary)),
        map(() => this.steps)
      );
  }

  getStepsOnLangChange(): Observable<Array<StepModel>> {
    return this._translateService
      .onLangChange
      .pipe(
        takeUntil(this._onDestroy),
        switchMap(({lang}) => this._translateService.getTranslation(lang)),
        tap(dictionary => this._initSteps(dictionary)),
        map(() => this.steps)
      );
  }

  markStep(index) {
    if (this.steps) {
      for (let i = 0; i < this.steps.length; i++) {
        if (i < index) {
          this.steps[i].stepType = 'done';
        } else {
          this.steps[i].stepType = 'pending';
        }
      }

      this.steps[index].stepType = 'active';
      this._steps$.next(this.steps);
    }
  }

  navigateToStep(stepIndex: StepEnum): void {
    if (
      this.steps &&
      stepIndex !== StepEnum.stepTwo &&
      stepIndex !== StepEnum.stepThree
    ) {
      const step: StepModel = this.steps.find(item => item.step === stepIndex);
      const {id} = this._registration;
      let navigateTo: string[];

      switch (step.stepGuard) {
        case StepGuardEnum.personalData:
          navigateTo = step.navigateTo();
          break;
        case StepGuardEnum.documentsPending:
        case StepGuardEnum.confirmationPending:
        case StepGuardEnum.regNotValid:
        case StepGuardEnum.resultPending:
          navigateTo = step.navigateTo(id);
          break;
        case StepGuardEnum.prepaymentPending:
          const {prepaymentInvoiceId} = this._registration;
          navigateTo = step.navigateTo(prepaymentInvoiceId, id);
          break;
        case StepGuardEnum.paymentPending:
          const {paymentInvoiceId} = this._registration;
          navigateTo = step.navigateTo(paymentInvoiceId, id);
          break;
        default:
          navigateTo = step.navigateTo();
      }

      this._router
        .navigate(navigateTo)
        .then(() => this.markStep(stepIndex));

    }
  }

  clearStepDataService(): void {
    this.steps = undefined;
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
