import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  constructor(private _httpClient: HttpClient) {
  }

  getQueueBumber() {
    return this._httpClient.get(`${environment.devUrl}/queue/QUEU00000000001`);
  }

  updateNumber(number) {
    return this._httpClient.put(`${environment.devUrl}/queue/QUEU00000000001`, {'id': 'QUEU00000000001', 'activeNumber': number});
  }

  resetNumber() {
    return this._httpClient.delete(`${environment.devUrl}/queue/QUEU00000000001`);
  }
}
