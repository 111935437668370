import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-profile-data-dialog',
  templateUrl: './profile-data-dialog.component.html',
  styleUrls: ['./profile-data-dialog.component.css']
})
export class ProfileDataDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ProfileDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() { }

  onSaveProfileDataSave(data) {
    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close();
  }
}
