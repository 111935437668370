const appName = 'new-student-admission';

export const BrowserConstant: Record<string, any> = {
  SessionStorage: {
    token: `${appName}:token`,
    email: `${appName}:logged-user-email`,
    anagraficaId: `${appName}:logged-user-anagrafica-id`
  },
  LocalStorage: {
    lang: `${appName}:lang`,
    uuid: `${appName}:uuid`
  }
};
