
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { UserDataService } from '../../../services/user-data.service';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/Observable/combineLatest';
import {PersonalDataService} from '../../../models/services/personal-data.service';
import {BrowserUser} from '../../../models/browser-user';

@Injectable()
export class RegistrationTypeGuard implements CanActivate {
  constructor(
    private _route: Router,
    private _userService: UserDataService,
    private _personalData: PersonalDataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._userService.getCurrentUser() && this._userService.personalData) {
      return true;
    } else {
      const userExists$ = this._userService.checkGetUser$()
        .do((userExists) => {
          if (!userExists) {
            this._route.navigate(['./profile']);
          }
        });

      const userPersonalData$ = this._personalData.getUserData(BrowserUser.getAnagraficaId())
        .do((userData) => {
          if (!userData) {
            this._route.navigate(['./profile']);
          }
        });

      return combineLatest<boolean>(userExists$, userPersonalData$).map((res: Array<any>) => {
          return res.reduce((accumulated, currentValue) => {
            return accumulated !== undefined && currentValue !== undefined;
          });
        });
    }
  }
}
