import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserDataService} from '../../services/user-data.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatSnackBar} from '@angular/material';
import {DialogWarningComponent} from '../../dialogs/dialog-warning/dialog-warning.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FormUtility} from '../../utitlities/FormUtilities';
import {User} from '../../models/user';
import {BrowserUser} from '../../models/browser-user';

@Component({
  selector: 'app-password-step',
  templateUrl: './password-step.component.html',
  styleUrls: ['./password-step.component.css']
})
export class PasswordStepComponent implements OnInit {
  @ViewChild('password') passwordFocus: ElementRef<any>;

  loginForm = new FormGroup({
    username: new FormControl(localStorage.getItem('unizkm_user_email')),
    password : new FormControl('', [Validators.required])
  });

  langData: any;
  inputType = 'password';
  uuid: string;
  currentUser: User;

  constructor(private _userDataService: UserDataService,
              private _router: Router,
              private _snack: MatSnackBar,
              private _activatedRoute: ActivatedRoute,
              private _dialog: MatDialog,
              private _translate: TranslateService) { }

  ngOnInit() {
    this._translate
      .getTranslation(BrowserUser.getLang())
      .subscribe((langData) => {
        this.langData = langData;
      });

    this.currentUser = this._userDataService.currentUser;
    this.uuid = this._activatedRoute.snapshot.paramMap.get('uuid');

    if (!this.currentUser || !this.uuid) {
      this._router
        .navigate(['./auth/signin'])
        .then();
    }

    this.passwordFocus.nativeElement.focus();
  }

  requireResetPass() {
    this._userDataService
      .requireReset(this.uuid)
      .subscribe((res: any) => {
        if (res.status.code === 'STATUS_OK') {

          this._snack
            .open(this.langData.reset_password.reset_message || '... Done.', null, {
              duration: 10000,
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            })
            .afterOpened()
            .subscribe(() => this._router.navigate(['/auth', 'singin']).then());

        } else {

          this.showWarning(res.status.message);

        }
      });
  }

  tooglePassword() {
    if (this.inputType === 'text') {
      this.inputType = 'password';
    } else {
      this.inputType = 'text';
    }
  }

  login() {
    if (this.loginForm.value.password) {
      this._userDataService
        .loginPass(this.uuid, this.loginForm.value.password)
        .subscribe((result: any) => {
          if (result.status.code === 'NOT_AUTHENTICATED') {
            this._userDataService.logout();
          } else if (result.status.code === 'STATUS_OK') {
            this._userDataService.loginUser(result.body.data);
            this._router.navigate(['/dashboard/profile']).then();
          } else {
            this.showWarning(result.status.message);
          }
      });
    } else {
      console.log('error');
      FormUtility.markAllDirty(this.loginForm);
    }
  }

  goBack() {
    this._userDataService.logout();
  }

  showWarning(message) {
    this._dialog.open(DialogWarningComponent, {
      width: '700px',
      data: {
        title: this.langData.global.warning,
        message: message,
        description: this.langData.login_step.warning.info
      }
    });
  }
}
