import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserDataService} from '../../services/user-data.service';
import {MatDialog} from '@angular/material';
import {DialogWarningComponent} from '../../dialogs/dialog-warning/dialog-warning.component';
import {FormUtility} from '../../utitlities/FormUtilities';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login-step',
  templateUrl: './login-step.component.html',
  styleUrls: ['./login-step.component.css']
})
export class LoginStepComponent implements OnInit {
  result: any;
  langData: any;
  loginForm = new FormGroup({
    username: new FormControl('', [ Validators.required, Validators.minLength(5),
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
  });

  constructor (
    public _dialog: MatDialog,
    private _router: Router,
    private _userDataService: UserDataService,
    private _translate: TranslateService
  ) { }

  ngOnInit() {
    this._translate.onLangChange.subscribe((langData) => {
      this.langData = langData;
    });
  }

  login() {
    if (this.loginForm.valid) {
      this._userDataService.loginUuid(this.loginForm.value.username).subscribe((result: any) => {
        if (result.status.code === 'STATUS_OK') {

          this._userDataService.currentUser = result.body.data;
          this._router.navigate(['auth/login', result.body.data.serviceUuid]);
        } else if (result.status.code === 'NO_CONTENT') {
          this.openDialog();
        } else if (result.status.code === 'NOT_AUTHENTICATED') {
        }
      });
    } else {
      FormUtility.markAllDirty(this.loginForm);
    }
  }

  openDialog() {
    this._dialog.open(DialogWarningComponent, {
      width: '700px',
      data: {
        title: this.langData.translations.global.warning,
        message: this.langData.translations.login_step.warning.user_not_exist,
        description: this.langData.translations.login_step.warning.info
      }
    });
  }
}
